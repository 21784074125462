import React, {useState} from 'react'
import SVG_Nunta from "../../../../assets/EventsTypes/nunta-icon.svg";
import SVG_Botez from "../../../../assets/EventsTypes/botez-icon.svg";
import SVG_Petrecere from "../../../../assets/EventsTypes/petrecere-icon.svg";
import SVG_Cununie from "../../../../assets/EventsTypes/cununie-icon.svg";
import SVG_Mot from "../../../../assets/EventsTypes/mot-icon.svg";
import SVG_Turta from "../../../../assets/EventsTypes/turta-icon.svg";
import SVG_Business from "../../../../assets/EventsTypes/business-icon.svg";
import SVG_Corporate_Party from "../../../../assets/EventsTypes/corporate_party-icon.svg";
import SVG_Conferinta from "../../../../assets/EventsTypes/conferinta-icon.svg";
import SVG_Team_Building from "../../../../assets/EventsTypes/team-building-icon.svg";
import "./EventTypeSelector.scss"

export default function EventTypeSelector(
    {
        svgHeight,
        handleJobSelect
    }) {

    const [showBusinessEvents, setShowBusinessEvents] = useState(false)


    return (<>
            <h4 className={"section-title"} style={{marginTop: 0, marginBottom: 0}}>Alegeți tipul evenimentului</h4>

            <div className={"event-category-selector"}>
                <div className={"event-category-item " + (showBusinessEvents ? '' : 'active')}
                     onClick={() => setShowBusinessEvents(false)}>
                    <div className={"icon"}></div>
                    <div className={"title"}>Evenimente private</div>
                </div>
                <div className={"event-category-item " + (showBusinessEvents ? 'active' : '')}
                     onClick={() => setShowBusinessEvents(true)}>
                    <div className={"icon"}></div>
                    <div className={"title"}>Evenimente business</div>
                </div>
            </div>

            {/*

conferinta
team_building
corporate_party
congress

            */}

            <div className="Input_Type_Options">

                {showBusinessEvents ?
                    <>

                        <div className="Input_Type_Option" onClick={_ => handleJobSelect("conferinta")}>
                            <div className='Circle'><img src={SVG_Conferinta} alt=""
                                                         style={{height: svgHeight}}/></div>
                            <div>Conferință</div>
                        </div>
                        <div className="Input_Type_Option" onClick={_ => handleJobSelect("team_building")}>
                            <div className='Circle'><img src={SVG_Team_Building} alt=""
                                                         style={{height: svgHeight}}/></div>
                            <div>Team Building</div>
                        </div>
                        <div className="Input_Type_Option" onClick={_ => handleJobSelect("corporate_party")}>
                            <div className='Circle'><img src={SVG_Corporate_Party} alt=""
                                                         style={{height: svgHeight}}/></div>
                            <div>Petrecere corporate</div>
                        </div>
                        {/*
                        <div className="Input_Type_Option" onClick={_ => handleJobSelect("congress")}>
                            <div className='Circle'><img src={SVG_Business} alt=""
                                                         style={{height: svgHeight}}/></div>
                            <div>Congres</div>
                        </div>*/}

                        {/*
                        <div className="Input_Type_Option" onClick={_ => handleJobSelect("ceremonie_absolvire")}>
                            <div className='Circle'><img src={SVG_Business} alt=""
                                                         style={{height: svgHeight}}/></div>
                            <div>Ceremonie absolvire</div>
                        </div>
                        <div className="Input_Type_Option" onClick={_ => handleJobSelect("bal_absolvire")}>
                            <div className='Circle'><img src={SVG_Business} alt=""
                                                         style={{height: svgHeight}}/></div>
                            <div>Bal absolvire</div>
                        </div>
                        <div className="Input_Type_Option" onClick={_ => handleJobSelect("ceremonie_bal_absolvire")}>
                            <div className='Circle'><img src={SVG_Business} alt=""
                                                         style={{height: svgHeight}}/></div>
                            <div>Ceremonie + bal de absolvire</div>
                        </div>
                        <div className="Input_Type_Option" onClick={_ => handleJobSelect("bal_boboci")}>
                            <div className='Circle'><img src={SVG_Business} alt=""
                                                         style={{height: svgHeight}}/></div>
                            <div>Bal de boboci</div>
                        </div>
                        <div className="Input_Type_Option" onClick={_ => handleJobSelect("revedere")}>
                            <div className='Circle'><img src={SVG_Business} alt=""
                                                         style={{height: svgHeight}}/></div>
                            <div>Revedere</div>
                        </div>*/}




                    </>
                    : <>

                        <div className="Input_Type_Option"  onClick={_ => handleJobSelect("Nunta")}>
                            <div className='Circle'><img src={SVG_Nunta} alt=""
                                                         style={{height: svgHeight}}/></div>
                            <div>Nuntă</div>
                        </div>

                        <div className="Input_Type_Option" onClick={_ => handleJobSelect("Botez")}>
                            <div className='Circle'><img src={SVG_Botez} alt=""
                                                         style={{height: svgHeight}}/></div>
                            <div>Botez</div>
                        </div>

                        <div className="Input_Type_Option" onClick={_ => handleJobSelect("Petrecere")}>
                            <div className='Circle'><img src={SVG_Petrecere} alt=""
                                                         style={{height: svgHeight}}/></div>
                            <div>Aniversare</div>
                        </div>

                        <div className="Input_Type_Option" onClick={_ => handleJobSelect("NuntaBotez")}>
                            <div className='Circle'><img src={SVG_Nunta} alt=""
                                                         style={{height: svgHeight}}/></div>
                            <div>Nuntă și Botez</div>
                        </div>

                        <div className="Input_Type_Option" onClick={_ => handleJobSelect("Cununie")}>
                            <div className='Circle'><img src={SVG_Cununie} alt=""
                                                         style={{height: svgHeight}}/></div>
                            <div>Cununie civilă</div>
                        </div>

                        <div className="Input_Type_Option" onClick={_ => handleJobSelect("Mot")}>
                            <div className='Circle'><img src={SVG_Mot} alt=""
                                                         style={{height: svgHeight}}/></div>
                            <div>Moț</div>
                        </div>
                        <div className="Input_Type_Option" onClick={_ => handleJobSelect("Turta")}>
                            <div className='Circle'><img src={SVG_Turta} alt=""
                                                         style={{height: svgHeight}}/></div>
                            <div>Turtă</div>
                        </div>
                    </>}
            </div>
        </>
    )

}
