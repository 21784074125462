import React from 'react'
import { Button, CheckTree } from "rsuite"
import "./Helper.scss"

import Modal from 'react-responsive-modal'
import {
	WhatsappShareButton,
	EmailShareButton
} from "react-share";
import whatsapp from '../assets/icons/whatsapp.svg'
import messenger from '../assets/icons/messenger.svg'
import gmail from '../assets/icons/gmail.svg'
import CustomInput from './custom/CustomInput';
import YouTube from 'react-youtube';
import {toast} from "react-toastify";
import {translateInviteTexts} from "../functions";
import {FormattedMessage} from "react-intl";

export let textarea_max_length = 250

//param: image
export function getUrlFromImage(image, original = false) {

	if (image === null || image === undefined) return ""

	if(original) {
		if(image.url) {
			return image.url
		}
	}

	if (image.formats === null || image.formats === undefined) return image.url

	if (image.formats.medium !== undefined) return image.formats.medium.url

	if (image.formats.large !== undefined) return image.formats.large.url

	if(image.url) {
		return image.url
	}



	if (image.formats.small !== undefined) return image.formats.small.url

	return image.url
}

export function fixRemoteInviteImageUrl(url) {

	if(!url.includes("https://admin.ality.ro")) {
		return url
	}

	url = url.replace("small_", "")
	url = url.replace("medium_", "")
	url = url.replace("large_", "")
	url = url.replace("thumbnail_", "")
	url = url.replace("https://admin.ality.ro/uploads/", "")

	url = url.split('?')
	url = url[0]

	return 'https://admin.ality.ro/invites/get_image/' + url
}

const maxWidth = 500;
const maxHeight = 500;
const imageQuality = 0.9;

export async function getBase64FromImage(image) {
	// console.log(image);

	let data = null;
	let imageObj = new Image();

	imageObj.src = window.URL.createObjectURL(image.blobFile);

	let promise = new Promise((resolve, reject) => {
		imageObj.onload = function () {
			// Resized image in base64
			data = resizeImage(imageObj, maxWidth, maxHeight, imageQuality);
			resolve();

			// eslint-disable-next-line
		}.bind(this);
	})

	await Promise.all([promise]);

	return data
}

export function resizeImage(image, maxWidth, maxHeight, quality) {
	let canvas = document.createElement('canvas');

	let width = image.width;
	let height = image.height;

	if (width > height) {
		if (width > maxWidth) {
			height = Math.round(height * maxWidth / width);
			width = maxWidth;
		}
	} else {
		if (height > maxHeight) {
			width = Math.round(width * maxHeight / height);
			height = maxHeight;
		}
	}

	canvas.width = width;
	canvas.height = height;

	let ctx = canvas.getContext("2d");
	ctx.drawImage(image, 0, 0, width, height);
	return canvas.toDataURL("image/jpeg", quality);
}

//params: onClick function, button text
export class BackButton extends React.Component {
	render() {
		return <div className="bottom-buttons">
			<Button onClick={this.props.function} className="back-button" style={{ ...this.props.style, color: "#1E1C59" }}>
				{this.props.text}
			</Button>
		</div>
	}
}

//params: onClick function, button text
//optional: loading
export class ConfirmButton extends React.Component {
	render() {
		let loading = false
		if (this.props.loading !== undefined) {
			loading = this.props.loading
		}

		return <div className="bottom-buttons">
			<Button loading={loading} onClick={this.props.function} className="add-button" style={{ ...this.props.style, backgroundColor: "#00afcc", color: "white" }}>
				{this.props.text}
			</Button>
		</div>
	}
}

//params: onClick function and text for back and confirm buttons
//optional: loading
export class BackAndConfirmButtons extends React.Component {
	render() {
		let loading = false
		if (this.props.loading !== undefined) {
			loading = this.props.loading
		}

		return <div className="bottom-buttons">
			<Button onClick={this.props.functionBack} className="back-button" style={{ ...this.props.styleBack, color: "#1E1C59" }}>
				{this.props.textBack}
			</Button>

			<Button loading={loading} onClick={this.props.functionConfirm} className="add-button" style={{ ...this.props.styleConfirm, backgroundColor: "#00afcc", color: "white" }}>
				{this.props.textConfirm}
			</Button>
		</div>
	}
}

export class LoadingScreen extends React.Component {
	render() {
		return <div>Loading data</div>
	}
}

//params: link to be shared
export class ShareModal extends React.Component {
	openFb = () => {
		window.open('fb-messenger://share?link=' + encodeURIComponent(this.props.customUrl ? this.props.customUrl : (process.env.REACT_APP_PAGE + "events/" + this.props.link + "/" + this.props.linkCategory)) + '&app_id=' + encodeURIComponent("1437009096459536"));
	}

	render() {
		let size = "70px"
		let url = process.env.REACT_APP_PAGE + "events/" + this.props.link + "/" + this.props.linkCategory

		if(this.props.customUrl) {
			url = this.props.customUrl
		}

		return (
			<Modal classNames={{
				overlay: 'modal-overlay',
				modal: 'helper-modal',
				closeButton: 'modal-close-button',
			}} open={this.props.showModal} onClose={this.props.closeModal} center>
				<div className="modal-content">
					<div style={{ marginTop: "10vh" }}>
						<div style={{ textAlign: "center", marginBottom: "40px" }}>Share la {decodeURI(this.props.link)}</div>

						<div className="icons">
							<WhatsappShareButton url={url} windowWidth={"1000"}
												 windowHeight={"600"} >
								<img src={whatsapp} alt="" style={{ height: size, width: size }} />
							</WhatsappShareButton>

							<img onClick={this.openFb} src={messenger} alt="" style={{ height: size, width: size }} />

							<EmailShareButton url={url}>
								<img src={gmail} alt="" style={{ height: size, width: size }} />
							</EmailShareButton>
						</div>
					</div>
				</div>
			</Modal>
		)
	}
}

//params: questions, answer, changeAnswers function
export class CustomQuestions extends React.Component {
	selectQuestionAnswer = (index, value) => {

		if(this.props.disabled) {
			toast.error(<FormattedMessage id={"event_page.participant_tab.custom_questions.no_changes_allowed"} />)
			return
		}

		let newAnswers = [...this.props.answers]

		if (newAnswers[index] === value || newAnswers[index] === value.value) {
			newAnswers[index] = ""
		} else {
			newAnswers[index] = value
			if (value.value !== undefined) {
				newAnswers[index] = value.value
			}
		}

		this.props.changeAnswers(newAnswers)
	}

	selectQuestionMultipleAnswer = (index, value) => {

		if(this.props.disabled) {
			toast.error(this.props.intl.formatMessage({id: "event_page.participant_tab.custom_questions.no_changes_allowed"}))
			return
		}

		let newAnswers = [...this.props.answers]


		let currentAnswersArray = newAnswers[index] ? newAnswers[index].split(',').map((item,i) => {
			return {
				label: item,
				value: item
			}
		}
		) : []

		if(value.value === 'Nu particip') {
			currentAnswersArray = [{label: this.props.intl.formatMessage({id: "event_page.participant_tab.invite.no_participate"}), value: 'Nu particip'}]
		} else {

			if(currentAnswersArray.find((item) => item.value === 'Nu particip')) {
				currentAnswersArray = []
			}

			//Toggle selection
			if (currentAnswersArray.find((item) => item.value === value.value)) {
				//Remove item from selection
				currentAnswersArray = currentAnswersArray.filter((item) => item.value !== value.value)
			} else {
				//Add item in selection
				currentAnswersArray.push({
					label: value.value,
					value: value.value
				})
			}
		}


		let newStringValue = ''
		for(let i=0; i<currentAnswersArray.length; i++) {
			newStringValue = newStringValue + currentAnswersArray[i].value + ','
		}
		newStringValue = newStringValue.replace(/,\s*$/, "");

		newAnswers[index] = newStringValue


		this.props.changeAnswers(newAnswers)
	}

	getQuestionTitle = (index) => {
		var newQuestions = [...this.props.questions]

		if (this.props.language && this.props.language !== 'ro' && newQuestions[index]['title_' + this.props.language] && newQuestions[index]['title_' + this.props.language].length > 0) {
			return newQuestions[index]['title_' + this.props.language]
		} else {
			return newQuestions[index].title
		}
	}

    getQuestionOptions = (index) => {
        var newQuestions = [...this.props.questions]

        if (this.props.language && this.props.language !== 'ro') {

            if (newQuestions[index]['options_' + this.props.language]) {
                let response = newQuestions[index]['options_' + this.props.language].map((item) => {return {'label':item, 'value':item}})
				let updatedResponse = []

				for(let i=0; i<response.length; i++) {
					if(response[i].label && response[i].label.length > 0) {
						updatedResponse.push(response[i])
					} else {
						if(newQuestions[index]['options'][i] && newQuestions[index]['options'][i].label) {
							updatedResponse.push({'label':newQuestions[index]['options'][i].label, 'value':newQuestions[index]['options'][i].value})
						}
					}
				}

				return updatedResponse

            } else {
                return newQuestions[index].options
            }
        } else {
            return newQuestions[index].options
        }
    }

	render() {
		return <div>
			{
				this.props.showTitle ?
					<div style={{borderBottom: '1px solid #ccc', padding: '1rem 0 1rem 0', fontSize: '15px'}}>
                        {translateInviteTexts('Întrebări opționale', this.props.language ? this.props.language : 'ro')}
                    </div>
					: null
			}
			{this.props.questions.map((question, index) => {

				//Check for conditions
				if(typeof question.conditionQuestionIndex !== "undefined" && question.conditionQuestionIndex > -1) {

					if(this.props.answers[question.conditionQuestionIndex]) {
						let valid = false

						//Find conditions
						let orConditions = question.condition.split('||')
						for(let i=0; i<orConditions.length; i++) {

							if(orConditions.length > 0) {
								if (this.props.answers[question.conditionQuestionIndex] === orConditions[i]) {
									valid = true
								}
							}
						}

						if(!valid) {
							return null
						}

					} else {
						return null
					}

				}

				return <div key={index} style={{ margin: "20px 0px", textAlign: "left" }} className={"custom-questions-cont"}>
					<h5 style={{fontSize:'18px', fontWeight:'300'}}>{this.getQuestionTitle(index)} {
						!!this.props.required ?
							<span style={{color:'red'}}>*</span>
							:null
					}</h5>

					{this.getQuestionTitle(index) === 'Alege modelul dorit (cost- 15 lei in plus)' ?
						<img src={"/images/custom/188.jpeg"} style={{width:'100%'}} alt={""}/>
					:null}

					{/* raspuns liber */}
					{question.type === 0 &&
						<CustomInput disabled={this.props.disabled} value={this.props.answers[index]} onChange={value => this.selectQuestionAnswer(index, value)}
									 placeholder={this.props.language ? translateInviteTexts('Răspuns liber', this.props.language ? this.props.language : 'ro') : this.props.intl.formatMessage({id: "event_page.participant_tab.custom_questions.free_answer"})} />
					}

					{/* raspuns cu variante */}
					{question.type === 1 &&
						<CheckTree
							disabled={this.props.disabled}
							defaultExpandAll
							renderTreeIcon={null}
							cascade={true}
							data={this.getQuestionOptions(index)}
							onSelect={value => this.selectQuestionAnswer(index, value)}
							value={[this.props.answers[index]]}
						/>
					}

					{/* raspuns cu variante multiple*/}
					{question.type === 2 &&
						<CheckTree
							disabled={this.props.disabled}
							defaultExpandAll
							renderTreeIcon={null}
							cascade={true}
							data={[{label: this.props.intl.formatMessage({id: "event_page.participant_tab.invite.no_participate"}), value: 'Nu particip'},...question.options]}
							onSelect={value => this.selectQuestionMultipleAnswer(index, value)}
							value={[...this.props.answers[index].split(',')]}
						/>
					}
				</div>
			})}
		</div>
	}
}


//params: link to audio to play
export class PlayAudio extends React.Component {

	render() {
		return <audio controls id={"audioMP3"}>
			<source src={ '' + this.props.link + ''} type={"audio/mp3"} />
		</audio>


	}
}

//params: link to video to play
export class PlayVideo extends React.Component {
	render() {
		return <YouTube videoId={this.props.videoId} containerClassName={"youtube-video"} />
	}
}
