import React from 'react'
import {Button, Input, InputGroup, Dropdown, Icon} from 'rsuite'
import Modal from 'react-responsive-modal'
import {toast} from 'react-toastify'

import './CustomQuestions.scss'
import {getLanguageFlag, getLanguageName, isCorporateWeddingEvent} from "../../../functions";

let maxNrOptions = 5
let maxNrQuestions = 10

class CustomQuestions extends React.Component {
    state = {
        eventId: this.props.eventId,

        questionTypes: [{name: "Raspuns liber", type: 0}, {name: "Grila", type: 1}],

        showModal: false,
        deleteIndex: -1,
        showForm: false,

        loading: true,
        loadingRequest: false,
        canModifyQuestions: this.props.canModifyQuestions,
        showNoticeModal: false,
        questions: this.props.questions,
        allowTranslation: false,
        language: this.props.language,
        languageForcedSet: false
    }

    // saveQuestionsToServer = () => {
    // 	let questions = [...this.state.questions]

    // 	//check questions for validity
    // 	for (let i = 0; i < questions.length; i++) {
    // 		if (questions[i].title === "") {
    // 			toast.error(`Va rog completati toate titlurile. Tilu intrebare ${i + 1}`)
    // 			return
    // 		}

    // 		if (questions[i].type === 1) {
    // 			if (questions[i].options.length === 0) {
    // 				toast.error(`Ati ales intrebare grila dar nu ati oferit optiuni. Intrebare ${i + 1}`)
    // 				return
    // 			}

    // 			for (let j = 0; j < questions[i].options.length; j++) {
    // 				if (questions[i].options[j] === "") {
    // 					toast.error(`Va rog completati toate optiunile unde ati ales grila. Intrebare ${i + 1}, grila ${j + 1}`)
    // 					return
    // 				}
    // 			}
    // 		}
    // 	}

    // 	let obj = {
    // 		questions: questions,
    // 		useCustomQuestions: this.state.shouldUseQuestions
    // 	}

    // 	let auth = {
    // 		headers: {
    // 			'Authorization': 'Bearer ' + localStorage.getItem('jwt')
    // 		}
    // 	}

    // 	axios.put(process.env.REACT_APP_DOMAIN + 'weddings/' + this.state.eventId, obj, auth)
    // 		.then(response => {
    // 			toast.success("Modificari salvate cu succes")
    // 		})
    // }

    // changeUseQuestionsState = async (state) => {
    // 	// let current = this.state.shouldUseQuestions

    // 	if (state === false && this.state.canModifyQuestions === false) {
    // 		toast.error("Aveti raspunsuri, modificarile nu mai sunt permise")
    // 		return
    // 	}

    // 	// if (state === current || this.state.loadingRequest) {
    // 	// 	return
    // 	// }

    // 	// this.setState({ loadingRequest: true })

    // 	// let obj = {
    // 	// 	useCustomQuestions: state
    // 	// }

    // 	// let auth = {
    // 	// 	headers: {
    // 	// 		'Authorization': 'Bearer ' + localStorage.getItem('jwt')
    // 	// 	}
    // 	// }

    // 	// await axios.put(process.env.REACT_APP_DOMAIN + 'weddings/' + this.state.eventId, obj, auth)
    // 	// 	.then(response => {
    // 	// 		if (state) {
    // 	// 			toast.success("Intrebari activate")
    // 	// 		} else {
    // 	// 			toast.success("Intrebari dezactivate")
    // 	// 		}

    // 	this.setState({
    // 		shouldUseQuestions: state
    // 	})
    // 	// 	})
    // 	// 	.catch(err => {
    // 	// 		console.log(err);
    // 	// 		toast.error("A aparut o eroare la server, va rog reincercati")
    // 	// 	})

    // 	// this.setState({ loadingRequest: false })
    // }

    componentDidMount() {
        this.initData()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.language !== this.props.language) {
            this.setState({
                language: this.props.language,
                questions: this.props.questions,
                allowTranslation: false,
                languageForcedSet: false,
                canModifyQuestions: this.props.canModifyQuestions,
            }, () => {
               this.initData()
            })
        }
    }

    initData = () => {
        // If questions are not defined and language is not Romanian
        if (this.props.event && (!this.props.event.questions || this.props.event.questions.length === 0) && this.state.language !== 'ro') {
            this.setState({
                language: 'ro',
                languageForcedSet: true
            })
            return
        }

        // If questions are defined and this invite use another language then romanian
        // then allow user to translate them
        if (this.props.event && this.props.event.questions && this.props.event.questions.length > 0 && this.state.language !== 'ro') {
            this.setState({
                canModifyQuestions: false,
                allowTranslation: true
            })
        }
    }

    addQuestion = () => {
        let oldQuestions = [...this.props.questions]

        if (oldQuestions.length >= maxNrQuestions) {
            toast.info(`Aveti numarul maxim de intrebari ${maxNrQuestions}`)
            return
        }

        let newQuestion = {
            type: 0,
            title: "",
            options: []
        }

        this.props.changeQuestions([...oldQuestions, newQuestion])
    }

    openModal = (index) => {
        this.setState({
            showModal: true,
            deleteIndex: index
        })
    }

    deleteQuestion = () => {
        let newQuestions = [...this.props.questions]
        newQuestions.splice(this.state.deleteIndex, 1)

        this.props.changeQuestions(newQuestions)

        this.closeModal()
    }

    getQuestionTitle = (index) => {
        var newQuestions = [...this.props.questions]

        if (this.state.language !== 'ro') {
            return newQuestions[index]['title_' + this.state.language]
        } else {
            return newQuestions[index].title
        }
    }

    getQuestionOptions = (index) => {
        var newQuestions = [...this.props.questions]

        if (this.state.language !== 'ro') {
            if (!newQuestions[index]['options_' + this.state.language]) {
                newQuestions[index]['options_' + this.state.language] = newQuestions[index].options.map((item) => '')
            }

            return newQuestions[index]['options_' + this.state.language]
        } else {
            return newQuestions[index].options
        }
    }

    changeQuestionTitle = (index, value) => {
        var newQuestions = [...this.props.questions]

        if (this.state.language !== 'ro') {
            newQuestions[index]['title_' + this.state.language] = value
        } else {
            newQuestions[index].title = value
        }

        this.props.changeQuestions(newQuestions)
    }

    changeQuestionType = (index, type) => {
        var newQuestions = [...this.props.questions]

        newQuestions[index].type = type

        this.props.changeQuestions(newQuestions)
    }

    changeQuestionOption = (questionIndex, optionIndex, value) => {
        var newQuestions = [...this.props.questions]

        if (this.state.language !== 'ro') {
            newQuestions[questionIndex]['options_' + this.state.language][optionIndex] = value
        } else {
            newQuestions[questionIndex].options[optionIndex] = value
        }
        this.props.changeQuestions(newQuestions)
    }

    addOptionToQuestion = (index) => {
        var newQuestions = [...this.props.questions]

        if (newQuestions[index].options.length >= maxNrOptions) {
            toast.info("Maxim " + maxNrOptions + " optiuni")
            return
        }

        newQuestions[index].options.push("")
        if (this.state.language !== 'ro') {
            if (newQuestions[index]['options_' + this.state.language]) {
                newQuestions[index]['options_' + this.state.language].push("")
            } else {
                newQuestions[index]['options_' + this.state.language] = []
                newQuestions[index]['options_' + this.state.language].push("")
            }
        }


        this.props.changeQuestions(newQuestions)
    }

    deleteOptionFromQuestion = (index, optionIndex) => {
        var newQuestions = [...this.props.questions]

        newQuestions[index].options.splice(optionIndex, 1)
        if (this.state.language !== 'ro') {
            newQuestions[index]['options_' + this.state.language].splice(optionIndex, 1)
        }

        this.props.changeQuestions(newQuestions)
    }

    closeModal = () => {
        this.setState({
            showModal: false,
            deleteIndex: -1
        })
    }

    triggerCustomQuestionsAlert = () => {
        toast.error("S-au primit raspunsuri la invitatie, modificarile la intrebari nu vor fi salvate")
    }

    render() {
        return (
            <div className="CustomQuestions-contents">
                <Modal classNames={{
                    overlay: 'modal-overlay',
                    modal: 'modal',
                    closeButton: 'modal-close-button',
                }} open={this.state.showModal} onClose={this.closeModal}>
                    {this.state.deleteIndex !== -1 && <div>
                        <h5 style={{marginTop: "20px"}}>Sigur doriti sa stergeti aceasta intrebare?</h5>

                        <p style={{margin: "20px 0px"}}>{this.props.questions[this.state.deleteIndex].title}</p>

                        <Button color="red" onClick={this.deleteQuestion}>Confirm stergerea intrebarii</Button>
                    </div>}
                </Modal>

                <div className="Title">Intrebări fixe</div>

                <div>Intrebări fixe: participă (da, nu, nu stie), nume, prenume, însoțit (nume însoțitor){
                    this.props.eventType !== 'Petrecere' ?
                        ', cu/fara copii'
                        : null
                }</div>

                <div className="CustomQuestions">
                    <div style={{marginTop: "20px"}}>Afișăm varianta de răspuns „Nu știu”?</div>
                    <div className="buttons">
                        <Button className={!this.props.hideDontKnowButton ? "button-active" : "button-inactive"}
                                 onClick={() => {
                                this.props.setHideDontKnowButton(false)
                        }} style={{marginRight: "30px"}}>
                            Da
                        </Button>

                        <Button className={this.props.hideDontKnowButton ? "button-active" : "button-inactive"}
                                onClick={() => {
                            this.props.setHideDontKnowButton(true)
                        }}>
                            Nu
                        </Button>
                    </div>

                    {
                        this.props.eventType !== 'Petrecere' ?
                            isCorporateWeddingEvent(this.props.eventType, true) ?
                                <><div style={{marginTop: "20px"}}>Afișăm întrebare număr de însoțitori?</div>
                                    <div className="buttons">
                                        <Button className={!this.props.hideChildrenQuestion ? "button-active" : "button-inactive"}
                                                onClick={() => {
                                                    this.props.setHideChildrenQuestion(false)
                                                }} style={{marginRight: "30px"}}>
                                            Da
                                        </Button>

                                        <Button className={this.props.hideChildrenQuestion ? "button-active" : "button-inactive"}
                                                onClick={() => {
                                                    this.props.setHideChildrenQuestion(true)
                                                }}>
                                            Nu
                                        </Button>
                                    </div></>
                                :
                            <><div style={{marginTop: "20px"}}>Afișăm întrebare număr de copii?</div>
                                <div className="buttons">
                                    <Button className={!this.props.hideChildrenQuestion ? "button-active" : "button-inactive"}
                                            onClick={() => {
                                                this.props.setHideChildrenQuestion(false)
                                            }} style={{marginRight: "30px"}}>
                                        Da
                                    </Button>

                                    <Button className={this.props.hideChildrenQuestion ? "button-active" : "button-inactive"}
                                            onClick={() => {
                                                this.props.setHideChildrenQuestion(true)
                                            }}>
                                        Nu
                                    </Button>
                                </div></>
                            : null
                    }

                    <div style={{marginTop: "20px"}}>Afișăm formularul de confirmare a participării ?</div>
                    <div className="buttons">
                        <Button className={!this.props.hideConfirmForm ? "button-active" : "button-inactive"}
                                onClick={() => {
                                    this.props.setHideConfirmForm(false)
                                }} style={{marginRight: "30px"}}>
                            Da
                        </Button>

                        <Button className={this.props.hideConfirmForm ? "button-active" : "button-inactive"}
                                onClick={() => {
                                    this.props.setHideConfirmForm(true)
                                }}>
                            Nu
                        </Button>
                    </div>

                    {!this.props.hideConfirmForm ? <>
                        <div style={{marginTop: "20px"}}>Solicităm adresa de email a participanților ?<br/> Activarea acestei opțiuni previne înregistrarea răspunsurilor duplicate.</div>
                        <div className="buttons">
                            <Button className={!this.props.hideEmailInput ? "button-active" : "button-inactive"}
                                    onClick={() => {
                                        this.props.setHideEmailInput(false)
                                    }} style={{marginRight: "30px"}}>
                                Da
                            </Button>

                            <Button className={this.props.hideEmailInput ? "button-active" : "button-inactive"}
                                    onClick={() => {
                                        this.props.setHideEmailInput(true)
                                    }}>
                                Nu
                            </Button>
                        </div>
                    </>:null}

                    <div style={{marginTop: "20px"}}>Aveți nevoie de alte întrebări?</div>
                    <div className="buttons">
                        <Button className={this.props.shouldUseQuestions ? "button-active" : "button-inactive"}
                                loading={this.state.loadingRequest} onClick={() => {
                            if (this.state.canModifyQuestions) {
                                this.props.changeUseQuestionsState(true)
                            } else {
                                this.setState({showNoticeModal: true})
                            }
                            this.setState({showForm: true})
                        }} style={{marginRight: "30px"}}>
                            Da
                        </Button>

                        <Button className={!this.props.shouldUseQuestions ? "button-active" : "button-inactive"}
                                loading={this.state.loadingRequest} onClick={() => {
                            if (this.state.canModifyQuestions) {
                                this.props.changeUseQuestionsState(false)
                            } else {
                                this.setState({showNoticeModal: true})
                            }
                            this.setState({showForm: false})
                        }}>
                            Nu
                        </Button>
                    </div>

                    {this.props.shouldUseQuestions && <div>
                        <div>Nu se mai pot aduce modificări la întrebările personalizate după primirea primului
                            răspuns.
                        </div>

                        {
                            this.state.allowTranslation ?
                                <div style={{color: 'red'}}>
                                    Vă rugăm asigurați-vă că ați tradus întrebările!
                                </div>
                                : null
                        }

                        {
                            this.state.languageForcedSet ?
                                <div style={{color: 'red'}}>
                                    Vă rugăm să definiți mai întâi setul de întrebări pentru invitația în limba română. Ulterior veți
                                    putea să traduceți întrebările!
                                </div>
                                :


                                this.state.showForm ?
                                    <>
                                        {this.props.questions.map((question, index) => {
                                            return <div key={index} className="CustomQuestion-Card">

                                                <p>Tipul intrebarii <span className={"lang-indicator"}>
												{getLanguageName(this.state.language)} {getLanguageFlag(this.state.language)}
											</span></p>
                                                <div>
                                                    <Dropdown title={this.state.questionTypes[question.type].name}
                                                              disabled={!this.state.canModifyQuestions}
                                                              onClick={() => {
                                                                  if (!this.state.canModifyQuestions) {
                                                                      this.setState({showNoticeModal: true})
                                                                  }
                                                              }}
                                                              activeKey={this.state.questionTypes[question.type].name}
                                                              className="dropdown_scroll"
                                                    >
                                                        {this.state.questionTypes.map((type, ind) => {
                                                            return <Dropdown.Item key={ind} eventKey={type.name}
                                                                                  onClick={() => this.changeQuestionType(index, type.type)}>
                                                                {type.name}
                                                            </Dropdown.Item>
                                                        })}
                                                    </Dropdown>
                                                </div>

                                                <p>Intrebare {index + 1}</p>

                                                <div onClick={() => {
                                                    if (!this.state.canModifyQuestions && !this.state.allowTranslation) {
                                                        this.setState({showNoticeModal: true})
                                                    }
                                                }}>
                                                    <p>Titlu</p>
                                                    <Input value={this.getQuestionTitle(index)}
                                                           disabled={!this.state.canModifyQuestions && !this.state.allowTranslation}
                                                           onChange={value => this.changeQuestionTitle(index, value)}
                                                           placeholder={this.state.allowTranslation ? question.title : ''}
                                                    />
                                                </div>
                                                {question.type === 1 && <div style={{marginTop: "20px"}}>
                                                    <div className="Add-Option">
                                                        <p>Variante de raspuns</p>

                                                        {/* <div onClick={() => this.addOptionToQuestion(index)} style={{display: "flex", flexDirection: "row"}}> */}
                                                        <Icon icon="plus" className="Add-Icon" onClick={() => {
                                                            if (this.state.canModifyQuestions)
                                                                this.addOptionToQuestion(index)
                                                            else
                                                                this.setState({showNoticeModal: true})
                                                        }}/>

                                                        {/* <div style={{marginLeft: "10px"}}>Adauga intrebare</div>
										</div> */}
                                                    </div>

                                                    {this.getQuestionOptions(index).map((option, optionIndex) => {
                                                        return <InputGroup className="InputGroup-Container" inside
                                                                           key={optionIndex} onClick={() => {
                                                            if (!this.state.canModifyQuestions && !this.state.allowTranslation) {
                                                                this.setState({showNoticeModal: true})
                                                            }
                                                        }}>
                                                            <Input value={option}
                                                                   disabled={!this.state.canModifyQuestions && !this.state.allowTranslation}
                                                                   onChange={value => this.changeQuestionOption(index, optionIndex, value)}
                                                                   placeholder={this.state.allowTranslation ? this.props.questions[index]['options'][optionIndex] : ''}
                                                            />

                                                            {!this.state.allowTranslation ?
                                                                    <InputGroup.Addon className="InputGroup-Icon">
                                                                        <Icon icon="close" className="Del-Icon"
                                                                              onClick={() => {
                                                                                  if (this.state.canModifyQuestions)
                                                                                      this.deleteOptionFromQuestion(index, optionIndex)
                                                                              }}/>
                                                                    </InputGroup.Addon>
                                                                    : null}
                                                        </InputGroup>
                                                    })}
                                                </div>}

                                                <div>
                                                    <Button color="red" onClick={() => {
                                                        if (this.state.canModifyQuestions)
                                                            this.openModal(index)
                                                        else
                                                            this.setState({showNoticeModal: true})
                                                    }} style={{marginTop: "20px"}}>Șterge întrebare</Button>
                                                </div>
                                            </div>
                                        })}

                                        <div style={{display: 'flex', flexDirection: 'row'}}>
                                            <div style={{
                                                flex: 1,
                                                marginTop: "5px",
                                                display: "flex",
                                                justifyContent: "center",
                                                flexDirection: 'column'
                                            }}>


                                                <div className='add-small-button' style={{marginBottom: '5px'}}
                                                     onClick={() => {
                                                         if (this.state.canModifyQuestions)
                                                             this.addQuestion()
                                                     }}>
                                                    <Icon icon="plus-circle" size="lg"
                                                          style={{color: "#05AFF2", marginRight: "10px"}}/>
                                                    <div style={{fontSize: '16px'}}>Adaugă
                                                        întrebare
                                                    </div>
                                                </div>

                                                <Button color="white" onClick={() => {
                                                    this.setState({showForm: false})
                                                }} style={{
                                                    width: "70%",
                                                    borderRadius: "20px",
                                                    backgroundColor: '#E3B505',
                                                    color: '#ffffff',
                                                    margin: '0 auto 20px auto'
                                                }}>Finalizare
                                                    întrebări</Button>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <Button color="white" onClick={() => {
                                            this.setState({showForm: true})
                                        }} style={{width: "100%", marginTop: '10px', borderRadius: "20px"}}>Arată
                                            întrebările</Button>
                                    </>
                        }

                        {/* {this.state.canModifyQuestions
							? <div style={{ marginTop: "30px" }}>
								<Button style={{ borderRadius: "80px", backgroundColor: "#00afcc", color: "white" }} onClick={this.saveQuestionsToServer}>Salveaza modificarile</Button>
							</div>
							: <p style={{ marginTop: "30px" }}>Aveti raspunsuri la invitatii, modificarile la intrebari nu mai sunt permise</p>
						} */}
                    </div>}
                </div>

                <Modal
                    classNames={{
                        overlay: 'modal-overlay',
                        modal: 'modal-no-top',
                        closeButton: 'modal-close-button',
                    }}
                    open={this.state.showNoticeModal}
                    focusTrapped={false}
                    onClose={() => {
                        this.setState({
                            showNoticeModal: false
                        })
                    }}
                >
                    <div style={{marginTop: "20px"}}>
                        <h3>Informații utile</h3>
                        <br/>
                        <p>S-au primit deja răspunsuri la invitație și numai este posibilă modificarea întrebărilor
                            personalizate. Daca totusi doriti sa aduceți modificări acestei secțiuni va trebui sa
                            stergeti toate răspunsurile primite pana în acest moment și apoi o sa puteți modifica
                            întrebările.</p>

                        <Button
                            onClick={() => {
                                this.setState({
                                    showNoticeModal: false
                                })
                            }}
                            color="green"
                            style={{
                                backgroundColor: "#05AFF2",
                                borderRadius: "20px",
                                width: "100%",
                                marginTop: "20px"
                            }}>Ok</Button>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default CustomQuestions;
