import React from 'react'
import { Button, Toggle, SelectPicker, CheckTree } from 'rsuite'
import './AddResponse.scss'
import HeaderWhite from '../../../components/HeaderWhite'
import { toast } from 'react-toastify'
import Modal from 'react-responsive-modal'
import axios from 'axios'
import CustomInput from '../../components/custom/CustomInput'
import Loader from "../../components/Loader";
import NavbarColor from "../../../components/NavbarColor";
import {isCorporateWeddingEvent} from "../../../functions";

// const foodOptions = [{ label: 'Meniu normal', value: 'normal' }, { label: 'Meniu vegetarian', value: 'vegetarian' },
// { label: 'Meniu post', value: 'post' }];

const ansOptions = [{ label: 'Participa', value: 'yes' }, { label: 'Nu știe daca participa', value: "don't know" },
{ label: 'Nu Participa', value: 'no' }]

// const limitaPersoaneMentionate = 5;

class AddResponse extends React.Component {
	state = {
		event_data: {},
		answer: "yes",
		nume: "",
		prenume: "",
		insotit: false,
		nume_insotitor: "",
		cuCopii: false,
		nr_copii: null,

		loading: true,
		questions: [],
		answers: [],

		isModalOpen: false,
	}

	componentDidMount = async () => {
		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		let response = await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getThisWedding/' + this.props.match.params.id, auth)

		let questions = response.data.questions
		if (!response.data.useCustomQuestions) {
			questions = []
		}
		if (questions === null) {
			questions = []
		}
		let answers = []

		for (let i = 0; i < questions.length; i++) {
			answers.push("")

			let newOptions = []

			for (let j = 0; j < questions[i].options.length; j++) {
				newOptions.push({ label: questions[i].options[j], value: questions[i].options[j] })
			}

			questions[i].options = newOptions
		}

		this.setState({
			event_data: response.data,
			questions: questions,
			answers: answers,
			loading: false
		})
	}

	showModal = () => {
		if (this.state.answer === "") {
			toast.error("Va rog alegeti raspunsul participantului")
			return
		}

		// if (this.state.nume === "") {
		// 	toast.error("Va rog introduceti numele invitatului")
		// 	return
		// }

		if (this.state.prenume === "") {
			toast.error("Va rog introduceti prenumele invitatului")
			return
		}

		if (this.state.insotit === true && this.state.nume_insotitor === "") {
			toast.error("Va rog introduceti numele insotitorului")
			return
		}
		if (this.state.cuCopii === true && this.state.nr_copii === null) {
			toast.error("Va rog mentionati cu cati copii vine invitatul")
			return
		}

		this.setState({
			isModalOpen: true
		})
	}

	closeModal = () => {
		this.setState({
			isModalOpen: false
		})
	}

	saveResponse = () => {
		this.closeModal()

		var obj = {
			answer: this.state.answer,
			nume: this.state.nume,
			prenume: this.state.prenume,
			insotit: this.state.insotit,
			nume_insotitor: this.state.nume_insotitor,
			cuCopii: this.state.cuCopii,
			nr_copii: parseInt(this.state.nr_copii + ''),
			hideChildrenQuestion: this.state.event_data.invite.hideChildrenQuestion
		}

		for (let i = 0; i < this.state.answers.length; i++) {
			obj["answer" + i] = this.state.answers[i]
		}

		var data = {
			event: this.state.event_data.id,
			response: obj,
			isOrganizer: true
		}

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		axios.post(process.env.REACT_APP_DOMAIN + 'responses/create', data, auth)
			.then(response => {
				toast.success("Raspuns trimis")

				this.goBack()
			})
	}

	setter = (key, value) => {
		this.setState({
			[key]: value
		})
	}

	selectQuestionAnswer = (index, value) => {
		var newAsnwers = [...this.state.answers]

		newAsnwers[index] = value
		if (value.value !== undefined)
			newAsnwers[index] = value.value

		this.setState({
			answers: newAsnwers
		})
	}

	goBack = () => {
		this.props.history.go(-1)
	}

	render() {
		if (this.state.loading) {
			return <div className="AddResponse-container">
				<HeaderWhite style={{ width: "100%" }} goBack={this.goBack} />

				<Loader/>

				<NavbarColor history={this.props.history} />
			</div>
		}

		let showData = (title, data) => {
			return <div>
				<h5>{title}</h5>
				<div style={{ marginBottom: "15px" }}>{data}</div>
			</div>
		}

		return (
			<div className="AddResponse-container">
				<HeaderWhite goBack={this.goBack} />

				<Modal classNames={{
					overlay: 'modal-overlay',
					modal: 'modal',
					closeButton: 'modal-close-button',
				}} open={this.state.isModalOpen} onClose={this.closeModal}>
					<div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", marginTop: "20px", width: "200px" }}>
						{showData("Raspuns", this.state.answer === "yes" ? "Participa" : this.state.answer === "no" ? "Nu Participa" : "Nu știe daca participa")}

						{/* {showData("Nume", this.state.nume)} */}

						{showData("Prenume, Nume", this.state.prenume)}

						{isCorporateWeddingEvent(this.state.event_data) ?
							<>
								{this.state.insotit && this.state.event_data.invite.hideChildrenQuestion ?
									showData("Insotit", this.state.insotit ? this.state.nume_insotitor : "Neinsotit")
									:null}

								{this.state.cuCopii && !this.state.event_data.invite.hideChildrenQuestion ?
									showData("Însoțitori", this.state.cuCopii ? this.state.nr_copii : "Fără"):null}
							</>
						:<>
						{showData("Insotit", this.state.insotit ? this.state.nume_insotitor : "Neinsotit")}
						{showData("Copii", this.state.cuCopii ? this.state.nr_copii : "Fara")}
					</>}

						{this.state.answers.map((ans, index) => {
							return <div key={index}>{showData(this.state.questions[index].title, ans)}</div>
						})}

						<Button onClick={this.saveResponse} style={{ alignSelf: "center", borderRadius: "80px", backgroundColor: "#00afcc", color: "white" }} color="green"> Confirma </Button>
					</div>
				</Modal>

				<div className="AddResponse-contents">
					<SelectPicker
						searchable={false}
						style={{ marginBottom: 20, marginTop: "30px" }}
						data={ansOptions}
						appearance="default"
						onChange={(value) => this.setState({ answer: value })}
						value={this.state.answer}
						placeholder="Raspuns"
					/>

					{/* <CustomInput value={this.state.nume} onChange={(value) => this.setState({ nume: value })} style={{ margin: "10px 0px" }} placeholder={"Nume"} /> */}

					<CustomInput value={this.state.prenume} onChange={(value) => this.setState({ prenume: value })} style={{ margin: "10px 0px" }} placeholder={"Prenume, Nume"} />


					{isCorporateWeddingEvent(this.state.event_data) ?
					<>
						<div style={{
							display: "flex",
							flexDirection: "row",
							marginTop: "10px",
							marginBottom: "20px",
							justifyContent: "space-around"
						}}>
							<p> Vine singur </p>
							<Toggle checked={this.state.event_data.invite.hideChildrenQuestion ? this.state.insotit : this.state.cuCopii}
									onChange={checked => this.setter(this.state.event_data.invite.hideChildrenQuestion ? "insotit" : "cuCopii", checked)}/>
							<p> Vine insoțit </p>
						</div>


						{this.state.insotit && this.state.event_data.invite.hideChildrenQuestion ?
							<CustomInput style={{margin: "10px 0px"}} value={this.state.nume_insotitor}
										 onChange={value => this.setter("nume_insotitor", value)}
										 placeholder="Nume Insotitor"/>
							:null}

						{this.state.cuCopii && !this.state.event_data.invite.hideChildrenQuestion ?
							<SelectPicker
								searchable={false}
								style={{marginBottom: 20}}
								data={[{label: "1", value: "1", role: "Nr copii"}, {
									label: "2",
									value: "2",
									role: "Nr copii"
								}, {label: "3", value: "3", role: "Nr copii"}, {
									label: "4",
									value: "4",
									role: "Nr copii"
								}, {label: "5", value: "5", role: "Nr copii"}]}
								appearance="default"
								onChange={(value) => this.setState({nr_copii: value})}
								value={this.state.nr_copii}
								placeholder="Numar insotitori"
								cleanable={false}
							/>:null}
					</>
						:
					<>
					<div style={{ display: "flex", flexDirection: "row", marginTop: "10px", marginBottom: "20px", justifyContent: "space-around" }}>
						<p> Vine singur </p>
						<Toggle checked={this.state.insotit} onChange={checked => this.setter("insotit", checked)} />
						<p> Vine insoțit </p>
					</div>
					{this.state.insotit && (
						<CustomInput style={{ margin: "10px 0px" }} value={this.state.nume_insotitor} onChange={value => this.setter("nume_insotitor", value)} placeholder="Nume Insotitor" />
					)}

					<p>Vin copii?</p>
					<div style={{ display: "flex", flexDirection: "row", marginTop: "10px", marginBottom: "20px", justifyContent: "space-around" }}>
						<p> Nu </p>
						<Toggle checked={this.state.cuCopii} onChange={checked => this.setter("cuCopii", checked)} />
						<p> Da </p>
					</div>
					{this.state.cuCopii && (
						<SelectPicker
							searchable={false}
							style={{ marginBottom: 20 }}
							data={[{ label: "1", value: "1", role: "Nr copii" }, { label: "2", value: "2", role: "Nr copii" }, { label: "3", value: "3", role: "Nr copii" }, { label: "4", value: "4", role: "Nr copii" }, { label: "5", value: "5", role: "Nr copii" }]}
							appearance="default"
							onChange={(value) => this.setState({ nr_copii: value })}
							placeholder="Numar copii insotitori"
							cleanable={false}
						/>
					)}
					</>
					}

					{this.state.questions.map((question, index) => {
						return <div key={index} style={{ margin: "20px 0px" }}>
							<h5>{question.title}</h5>

							{question.type === 0 &&
								<CustomInput value={this.state.answers[index]} onChange={(value) => this.selectQuestionAnswer(index, value)} style={{ margin: "10px 0px" }} placeholder={"Introduceti Raspuns"} />
							}

							{question.type === 1 &&
								<CheckTree
									defaultExpandAll
									renderTreeIcon={null}
									cascade={true}
									data={question.options}
									onSelect={value => this.selectQuestionAnswer(index, value)}
									value={[this.state.answers[index]]}
								/>
							}
						</div>
					})}

					<div><Button onClick={this.showModal} style={{ marginTop: 20, marginBottom: 20, borderRadius: "80px", backgroundColor: "#00afcc", color: "white" }} color="green">Salveaza</Button></div>
				</div>
			</div>
		)
	}
}

export default AddResponse;