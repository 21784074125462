import React from 'react'
// import './EventPage.scss'
import HeaderWhite from '../../components/HeaderWhite'
import NavBarEvents from '../../components/eventsPage/NavBarEvents'
import NavbarColor from '../../components/NavbarColor'

import GeneralTab from './EventPage/GeneralTab'
import AlbumsTab from './EventPage/AlbumsTab'
import FeedTab from './EventPage/FeedTab'
import FaceRecog from './FaceRecog/FaceRecog'
import MenuTab from './MenuTab/MenuTab'
import GiftTab from './EventPage/GiftTab'
import {toast} from 'react-toastify'
import axios from 'axios'
import ResponseTab from './EventPage/ResponseTab'
import {Button, Icon} from 'rsuite'
import _ from "lodash";
import TestimonialsTab from "../../secondaryPages/EventPage/TestimonialsTab";
import MainInvite2 from "../Invites/Template1/MainInvite2";
import MainInviteW from "../Invites/Template1/MainInvite";
import Modal from "react-responsive-modal";
import Loader from "../../components/Loader";

class EventPage extends React.Component {
    state = {
        activePage: "Loading",
        event_data: null,
        user_data: null,
        response_data: null,

        sideBarIsOn: false,
        tabOptions: [],
        tabOptionsIcons: [],
        showCheckerTutorial: false
    }

    componentDidMount = async () => {
        let isAndroid = navigator.userAgent.toLowerCase().indexOf("android") > -1

        if (isAndroid) {
            let viewHeight = window.innerHeight
            let viewport = document.querySelector("meta[name=viewport]");
            viewport.setAttribute("content", "height=" + viewHeight + "px, width=device-width, initial-scale=1.0")
            //document.write('<meta name ="viewport" content="width=device-width, height=' + window.innerHeight+'initial-scale=1.0">');
        }

        await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getWedding/' + this.props.match.params.id)
            .then(response => {
                this.setState({
                    event_data: _.cloneDeep(response.data)
                })
            })
            .catch(err => {
                console.log(err);

                toast.error("Cod eveniment incorect")
                this.props.history.push("/events")
            })

        let userId = localStorage.getItem("id")

        if (userId !== null) {
            let auth = {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                }
            }

            await axios.get(process.env.REACT_APP_DOMAIN + 'users/me', auth)
                .then((response) => {
                    this.setState({
                        user_data: response.data,
                    })
                })
                .catch(err => {
                    if(err && err.response && err.response.status === 401) {
                        localStorage.clear()
                        toast.error("Informațiile de autentificare au expirat, vă rugăm să vă autentificați!")
                        this.props.history.push('/login');
                    } else {
                        toast.error('A apărut o eroare! Vă rugăm reîncercați!')
                    }
                })

            let data = {
                id_eveniment: this.props.match.params.id,
                id_user: userId
            }

            await axios.post(process.env.REACT_APP_DOMAIN + 'responses/checkAttending', data, auth)
                .then(response => {
                    let participantData = response.data.participate

                    if (participantData === false) {
                        toast.error("Nu participati la acest eveniment")
                        this.props.history.push('/events');
                        return
                    } else {
                        if (window.alityAppView) {
                            window.ReactNativeWebView.postMessage(JSON.stringify({
                                action: 'askForReview'
                            }))
                        }
                    }

                    this.setState({
                        response_data: participantData,
                        showCheckerTutorial: participantData && participantData.is_checker && !participantData.checker_tutorial_seen
                    })
                })
                .catch(err => {
                    localStorage.clear()
                    toast.error("Informatiile de autentificare au expirat, va rugam sa va autentificati.")
                    this.props.history.push('/login');
                })
        }

        var newTabOptions = ["Eveniment"]
        var newTabOptionsIcons = ["star"]

        if (this.state.response_data !== null && this.state.response_data.is_checker === true) {
            newTabOptions.push("Check-in")
            newTabOptionsIcons.push("file")
        }

        if (localStorage.getItem("id") !== null) {
            newTabOptions.push("Răspuns invitație")
            newTabOptionsIcons.push("user-info")
        }

        if (this.state.event_data.invite && this.state.event_data.invite.isPublished) {
            newTabOptions.push("Invitatie")
            newTabOptionsIcons.push("book2")
        }

        if (this.state.event_data.isSeatingChartDone) {
            newTabOptions.push("Plan Locatie")
            newTabOptionsIcons.push("braille")
        }

        newTabOptions.push("Chat")
        newTabOptionsIcons.push("comments")

        if (this.state.event_data.areAlbumsPublished) {
            newTabOptions.push("Albume")
            newTabOptionsIcons.push("camera")
        }

        /*
        if (window.alityAppView && this.state.event_data.packages && this.state.event_data.packages.wedding_testimonials) {
            newTabOptions.push("Testimoniale")
            newTabOptionsIcons.push("video-camera")
        }*/

        if (this.state.event_data.isUsingFaceRecog) {
            newTabOptions.push("Face Recog")
            newTabOptionsIcons.push("video-camera")
        }

        if (this.state.event_data.areGiftsPublished) {
            newTabOptions.push("Cadouri")
            newTabOptionsIcons.push("gift")
        }

        if (this.state.event_data.menuFile !== null) {
            newTabOptions.push("Meniu")
            newTabOptionsIcons.push("book2")
        }

        if (this.state.event_data.saveTheDate && this.state.event_data.saveTheDate.isPublished) {
            newTabOptions.push("Save the date")
            newTabOptionsIcons.push("book2")
        }

        this.setState({
            activePage: "Eveniment",
            tabOptions: newTabOptions,
            tabOptionsIcons: newTabOptionsIcons
        })
    }

    hideCheckerTutorial = async () => {

        this.setState({
            showCheckerTutorial: false
        })

        var data = {
            checker_tutorial_seen: true
        }

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        axios.put(process.env.REACT_APP_DOMAIN + 'responses/updateMyResponse/' + this.state.response_data.id, data, auth)
            .then(response => {

            })
    }

    changeMenu = (type) => {
        if (type === 'Check-in')
            this.openHostess();

        if (type === 'Plan Locatie')
            this.openSeatingChart();

        this.setState({
            activePage: type
        })
    }

    updateResponse = (response) => {
        this.setState({
            response_data: response
        })
    }

    openSeatingChart = () => {
        this.props.history.push('/seatingChart/' + this.props.match.params.id)
    }

    openHostess = () => {
        this.props.history.push('/hostessMap/' + this.props.match.params.id)
    }

    showSideBar = () => {
        var currentState = this.state.sideBarIsOn

        this.setState({
            sideBarIsOn: !currentState
        })
    }

    render() {
        if (this.state.activePage === "Loading") {
            return <div className="EventPage-container">
                <HeaderWhite shadow={false}/>

                <div style={{height: "10vh"}}></div>

                <div className="EventPage-contents"><Loader/></div>

                <NavbarColor history={this.props.history}/>
            </div>
        }

        let toRender = null;
        if (this.state.activePage === "Eveniment")
            toRender = <GeneralTab event_id={this.state.event_data.id} history={this.props.history}
                                   event_data={this.state.event_data}
                                   user_data={this.state.user_data} response_data={this.state.response_data}
                                   updateResponse={this.updateResponse}/>

        if (this.state.activePage === "Răspuns invitație")
            toRender = <ResponseTab event_data={this.state.event_data} user_data={this.state.user_data}
                                    response_data={this.state.response_data} updateResponse={this.updateResponse}/>

        if (this.state.activePage === "Albume")
            toRender = <AlbumsTab event_id={this.props.match.params.id}/>

        /*
        if (this.state.activePage === "Testimoniale")
            toRender = <TestimonialsTab event_id={this.props.match.params.id} user_data={this.state.user_data}/>
         */

        if (this.state.activePage === "Chat")
            toRender = <FeedTab response_data={this.state.response_data} event_id={this.props.match.params.id}/>

        if (this.state.activePage === "Face Recog")
            toRender = <FaceRecog event_id={this.props.match.params.id} event_data={this.state.event_data}
                                  user_data={this.state.user_data}/>

        if (this.state.activePage === "Cadouri")
            toRender = <GiftTab event_id={this.props.match.params.id} event_data={this.state.event_data}
                                user_data={this.state.user_data}/>

        if (this.state.activePage === "Meniu")
            toRender = <MenuTab event_id={this.props.match.params.id}/>

        if (this.state.activePage === "Invitatie")
            toRender = <MainInvite2
                match={{
                    params: {
                        id: this.props.match.params.id
                    }
                }}
                disableAction={true}
            />

        if (this.state.activePage === "Save the date")
            toRender = <MainInviteW
                match={{
                    params: {
                        id: this.props.match.params.id
                    }
                }}
                disableAction={true}
            />

        return (
            <div className="EventPage-container font-family-poppins-300">
                <HeaderWhite shadow={false}/>

                <div className="EventPage-contents">
                    <NavBarEvents
                        activePage={this.state.activePage}
                        changeMenu={this.changeMenu}
                        showSideBar={this.showSideBar}
                        isRedCircleNeeded={() => false}
                        tabOptions={this.state.tabOptions}
                        isDotNeeded={false}
                    />

                    <div className="PageContent">
                        <div>
                            {toRender}
                        </div>
                    </div>
                </div>

                {this.state.sideBarIsOn && <div>
                    <div className="side-bar">
                        {this.state.tabOptions.map((option, index) => {
                            return <div onClick={() => this.changeMenu(option)} className="side-bar-line" key={option} style={option === 'Check-in' ? {border: '2px solid rgb(25, 184, 153)', borderRightWidth:0, borderLeftWidth:0, backgroundColor:'rgba(25, 184, 153,0.3)'} : {} }>
                                <Icon icon={this.state.tabOptionsIcons[index]}
                                      style={{color: this.state.activePage === option ? "#1DE9B6" : "white"}}/>
                                <p className={this.state.activePage === option ? "side-bar-item-active" : "side-bar-item"}>{option}</p>
                                <div></div>
                            </div>
                        })}
                    </div>
                    <div className="side-bar-blur" onClick={this.showSideBar}></div>
                </div>}


                <Modal
                    classNames={{
                        overlay: 'modal-overlay',
                        modal: 'modal-no-top',
                        closeButton: 'modal-close-button',
                    }}
                    open={this.state.showCheckerTutorial}
                    focusTrapped={false}
                    onClose={() => this.hideCheckerTutorial()}
                >
                    <div style={{marginTop: "20px"}}>
                        <h3>Informații utile</h3>
                        <br/>
                        <p>
                            text pentru hostess
                        </p>

                        <Button
                            onClick={() => this.hideCheckerTutorial()}
                            color="green"
                            style={{backgroundColor: "#05AFF2", borderRadius: "20px", width: "100%", marginTop:"20px"}}>Ok</Button>
                    </div>
                </Modal>

                <NavbarColor history={this.props.history}/>
            </div>
        )
    }
}

export default EventPage;
