import React from 'react'
import { InputGroup, Input, Icon, Button, Dropdown } from 'rsuite'
import './Responses.scss'
import HeaderWhite from '../../../components/HeaderWhite'
import NavbarColor from '../../../components/NavbarColor'
import Modal from 'react-responsive-modal'
import axios from 'axios'

import DataTable from './ForInviteResponses/DataTable'
import {BackAndConfirmButtons, BackButton, DataCards, ShowPackageData} from '../../components/Helper'
import Loader from "../../components/Loader";
import {getWeddingParticipantsPackageNumber} from "../../../functions";

let participa = "yes";
let nuParticipa = "no";
let nuStieDacaParticipa = "don't know";
let all = "toti";

class InviteResponses extends React.Component {
	state = {
		invites: [],
		dispInv: [],
		totalAnswers: 0,
		yesAnswers: 0,
		dontKnowAnswers: 0,
		noAnswers: 0,
		modalRaport: false,

		questions: [],
		questionsFilterOptions: [],

		modalFilter: false,
		modalInfo: false,
		participationChoice: all,
		partnerChoice: all,
		kidsChoice: all,
		timeChoice: all,
		tutorialModal: false,
		participateFamilies: 0,
		totalFamilies: 0,
		noParticipateFamilies: 0,
		dontKnowParticipateFamilies: 0,

		mentionToDisplay: "",

		currentSearch: "",

		packageData: {},

		packageType: 0,
		needsToUpgrade: false,
		maxReplies: 5,
		event: null,

		loading: true,
	}

	componentDidMount = async () => {
		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		//get custom questions amount
		let event_data = await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getThisWedding/' + this.props.match.params.id, auth)
		let questions = event_data.data.questions
		if (!event_data.data.useCustomQuestions) {
			questions = []
		}

		if (questions === null) {
			questions = []
		}

		this.setState({
			tutorialModal: !event_data.data.tutorials || (event_data.data.tutorials && !event_data.data.tutorials.responsesInvite),
			event: event_data.data
		})

		//get answers
		await axios.get(process.env.REACT_APP_DOMAIN + 'responses/list/byEvent/' + this.props.match.params.id, auth)
			.then(async response => {
				let yesAnswers = 0
				let dontKnowAnswers = 0
				let noAnswers = 0
				let	totalFamilies = 0
				let participateFamilies = 0
				let noParticipateFamilies = 0
				let dontKnowParticipateFamilies = 0

				let invites = response.data.responses.filter((element) => !element.deleted)

				 invites = invites.map(invitee => {
					var data = invitee.response

					//options: "yes", "don't know", "no"
					var ans = data.answer

					var totalPeople = 1
					totalFamilies++

					if (ans === "yes") {
						participateFamilies++
						if (data.insotit) {
							totalPeople++
						}

						if (data.cuCopii) {
							totalPeople += Number(data.nr_copii)
						}

						yesAnswers = yesAnswers + totalPeople
						if (totalPeople > 1) {
							totalPeople += " pers"
						} else {
							totalPeople = "1 pers"
						}

						let elem = {
							answer: ans,
							name: data.nume,
							prenume: data.prenume,
							insotit: data.insotit,
							nume_insotitor: data.nume_insotitor,
							cuCopii: data.cuCopii,
							nr_copii: data.nr_copii,
							totalPeople: totalPeople,
							responseId: invitee.id,
							created_at: invitee.created_at,
							hideChildrenQuestion: data.hideChildrenQuestion
						}

						for (let i = 0; i < questions.length; i++) {
							elem["answer" + i] = invitee.response["answer" + i]
						}

						return elem
					} else {


						if (data.insotit) {
							totalPeople++
						}

						if (data.cuCopii) {
							totalPeople += Number(data.nr_copii)
						}

						if (ans === "no") {
							noAnswers = noAnswers + totalPeople
							noParticipateFamilies++

						} else {
							dontKnowAnswers = dontKnowAnswers + totalPeople
							dontKnowParticipateFamilies++
						}

						if (totalPeople > 1) {
							totalPeople += " pers"
						} else {
							totalPeople = "1 pers"
						}





						let elem = {
							answer: ans,
							name: data.nume,
							prenume: data.prenume,
							insotit: data.insotit,
							nume_insotitor: data.nume_insotitor,
							cuCopii: data.cuCopii,
							nr_copii: data.nr_copii,
							totalPeople: totalPeople,
							responseId: invitee.id,
							created_at: invitee.created_at,
							hideChildrenQuestion: data.hideChildrenQuestion
						}

						for (let i = 0; i < questions.length; i++) {
							elem["answer" + i] = ""
						}

						return elem
					}
				})

				//-----------
				// if (invites.length > this.state.maxReplies) {
				// 	invites = invites.filter((_, index) => index < 5)

				// 	this.setState({
				// 		needsToUpgrade: true
				// 	})
				// }
				//-----------

				let questionsFilterOptions = []

				for (let i = 0; i < questions.length; i++) {
					questionsFilterOptions.push(all)
				}


				invites = invites.sort(function(a,b){
					// Turn your strings into dates, and then subtract them
					// to get a value that is either negative, positive, or zero.
					return new Date(b.created_at) - new Date(a.created_at);
				});

				if(invites.length >= 10) {
					//Ask for review
					if (window.alityAppView) {
						window.ReactNativeWebView.postMessage(JSON.stringify({
							action: 'askForReview'
						}))
					}
				}

				this.setState({
					invites: invites,
					dispInv: invites,
					packageData: response.data,
					totalAnswers: yesAnswers + dontKnowAnswers + noAnswers,
					totalFamilies: totalFamilies,
					participateFamilies: participateFamilies,
					noParticipateFamilies: noParticipateFamilies,
					dontKnowParticipateFamilies: dontKnowParticipateFamilies,
					yesAnswers: yesAnswers,
					dontKnowAnswers: dontKnowAnswers,
					noAnswers: noAnswers,
					loading: false,
					questions: questions,
					questionsFilterOptions: questionsFilterOptions
				})

				let totalResponses = response.data.totalResponses
				let receivedResponses = response.data.responses.length
				let seenResponses = event_data.data.count_seen_invite_responses

				let updateSeenRsponses = false
				let newSeenResponsesCount = seenResponses

				if (totalResponses > seenResponses) {
					if (totalResponses === receivedResponses) {
						//limit not passed
						updateSeenRsponses = true
						newSeenResponsesCount = totalResponses
					} else {
						//limit passed, check 2
						if (receivedResponses > seenResponses) {
							updateSeenRsponses = true
							newSeenResponsesCount = receivedResponses
						}
					}
				}

				if (updateSeenRsponses) {
					let obj = {
						count_seen_invite_responses: newSeenResponsesCount
					}

					axios.put(process.env.REACT_APP_DOMAIN + 'weddings/' + event_data.data.id, obj, auth)
				}
			})
	}

	filterInvites = (filterStep, filterChoice) => {
		let allParticipants = [...this.state.invites];

		//filter by participation
		let participationFilterChoice = this.state.participationChoice
		if (filterStep === 1) {
			participationFilterChoice = filterChoice
		}
		let filteredByParticipation = allParticipants.filter(element => participationFilterChoice === all || element.answer === participationFilterChoice)

		//filter by name
		let nameFilterChoice = this.state.currentSearch.toLocaleLowerCase()
		if (filterStep === 2) {
			nameFilterChoice = filterChoice.toLocaleLowerCase()
		}
		let filteredByName = filteredByParticipation.filter(element => {
			return element.name.toLocaleLowerCase().indexOf(nameFilterChoice) !== -1 ||
				element.prenume.toLocaleLowerCase().indexOf(nameFilterChoice) !== -1 ||
				element.nume_insotitor.toLocaleLowerCase().indexOf(nameFilterChoice) !== -1
		})

		//filter by partner
		let partnerFilterChoice = this.state.partnerChoice
		if (filterStep === 3) {
			partnerFilterChoice = filterChoice.toLocaleLowerCase()
		}
		let filteredByPartner = filteredByName.filter(element => {
			return partnerFilterChoice === all
				|| (element.insotit === true && partnerFilterChoice === "yes")
				|| (element.insotit === false && partnerFilterChoice === "no")
		})

		//filter by kids
		let kidsFilterChoice = this.state.kidsChoice
		if (filterStep === 4) {
			kidsFilterChoice = filterChoice.toLocaleLowerCase()
		}
		let filteredByKids = filteredByPartner.filter(element => {
			return kidsFilterChoice === all
				|| (element.cuCopii === true && kidsFilterChoice === "yes")
				|| (element.cuCopii === false && kidsFilterChoice === "no")
		})

		//filter by time interval
		let intervalFilterChoice = this.state.timeChoice
		if (filterStep === 6) {
			intervalFilterChoice = filterChoice.toLocaleLowerCase()
		}

		let now = new Date().getTime()
		let filteredByInterval = filteredByKids.filter(element => {
			if (intervalFilterChoice === all) {
				return true
			}

			let interval = 86400000//oneDayInMilliseconds

			if (intervalFilterChoice === "7") {
				interval *= 7
			} else if (intervalFilterChoice === "30") {
				interval *= 30
			}

			return new Date(element.created_at).getTime() > now - interval
		})

		//filter by custom questions
		let customQuestionsFilters = this.state.questionsFilterOptions
		if (filterStep === 5) {
			customQuestionsFilters = filterChoice
		}
		let finalResult = [...filteredByInterval]

		customQuestionsFilters.map((filter, index) => {
			if (filter === all) return null

			let newResult = finalResult.filter(
				element => this.state.questions[index].type === 0
					? (element["answer" + index] !== "" && filter === participa) || (element["answer" + index] === "" && filter === nuParticipa)
					: element["answer" + index] === filter
			)

			finalResult = [...newResult]

			return null
		})



		this.setState({
			dispInv: finalResult
		})
	}

	updateTutorial = async () => {

		this.setState({
			tutorialModal: false
		})

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		 await axios.post(process.env.REACT_APP_DOMAIN + 'weddings/update/tutorials/' + this.props.match.params.id,
			{
				responsesInvite: true
			},
			auth)

	}

	changeParticipationChoice = (choice, shouldCloseModal = true) => {
		this.setState({
			participationChoice: choice,
			modalFilter: !shouldCloseModal,
		})

		this.filterInvites(1, choice)
	}

	onChange = (value) => {
		this.setState({
			currentSearch: value
		})

		this.filterInvites(2, value)
	}

	changePartnerChoice = (choice, shouldCloseModal = true) => {
		this.setState({
			partnerChoice: choice,
			modalFilter: !shouldCloseModal,
		})

		this.filterInvites(3, choice)
	}

	changeKidsChoice = (choice, shouldCloseModal = true) => {
		this.setState({
			kidsChoice: choice,
			modalFilter: !shouldCloseModal,
		})

		this.filterInvites(4, choice)
	}

	changeQuestionFilterChoice = (choice, index, shouldCloseModal = true) => {
		let oldFilters = [...this.state.questionsFilterOptions]

		oldFilters[index] = choice

		this.setState({
			questionsFilterOptions: oldFilters,
			modalFilter: !shouldCloseModal,
		})

		this.filterInvites(5, oldFilters)
	}

	changeTimeChoice = (choice, shouldCloseModal = true) => {
		this.setState({
			timeChoice: choice,
			modalFilter: !shouldCloseModal,
		})

		this.filterInvites(6, choice)
	}

	toggleRaport = () => {
		let current = this.state.modalRaport;

		this.setState({
			modalRaport: !current
		})
	}

	openModalFilters = () => {
		this.setState({
			modalFilter: true
		})
	}

	closeModalFilters = () => {
		this.setState({
			modalFilter: false
		})
	}

	openModalInfo = () => {
		this.setState({
			modalInfo: true
		})
	}

	closeModalInfo = () => {
		this.setState({
			modalInfo: false
		})
	}

	goToAddResponse = () => {
		if(getWeddingParticipantsPackageNumber(this.state.event) < 1) {
			this.props.history.push('/organizer/noPremiumPackage/' + this.props.match.params.id)
			return;
		}
		this.props.history.push("/organizer/adaugaRaspuns/" + this.props.match.params.id)
	}

	goToUploadExcel = () => {
		if(getWeddingParticipantsPackageNumber(this.state.event) < 1) {
			this.props.history.push('/organizer/noPremiumPackage/' + this.props.match.params.id)
			return;
		}
		this.props.history.push("/organizer/uploadExcelResponses/" + this.props.match.params.id)
	}

	goToEditResponse = (index) => {
		this.props.history.push("/organizer/editeazaRaspuns/" + this.props.match.params.id + "/" + this.state.dispInv[index].responseId)
	}

	goBack = () => {
		this.props.history.goBack()
	}

	render() {
		if (this.state.loading === true) {
			return <div className="Responses-container">
				<HeaderWhite style={{ width: "100%" }} goBack={this.goBack} />

				<Loader/>

				<NavbarColor history={this.props.history} />
			</div>
		}

		return (
			<div className="Responses-container">
				{/* <Modal classNames={{
					overlay: 'modal-overlay',
					modal: 'modal',
					closeButton: 'modal-close-button',
				}} open={this.state.modalRaport} onClose={this.toggleRaport}>
					<div>
						<h5> Raport raspunsuri </h5>

						<p style={{ marginTop: "5vh" }}> Participa: {this.state.yesAnswers} </p>
						<p style={{ marginTop: "5vh" }}> Nu participa: {this.state.noAnswers} </p>
						<p style={{ marginTop: "5vh" }}> Nu stie daca participa: {this.state.dontKnowAnswers} </p>
						<p style={{ marginTop: "5vh" }}> Total raspunsuri: {this.state.totalAnswers} </p>
					</div>
				</Modal> */}

				<Modal classNames={{
					overlay: 'modal-overlay',
					modal: 'modal',
					closeButton: 'modal-close-button',
				}} open={this.state.modalFilter} onClose={this.closeModalFilters}>
					<div className="Responses-participation-modal">
						<p>Stare participare</p>
						<Dropdown title={this.state.participationChoice === all ? "Toti" : this.state.participationChoice === "yes" ? "Participa" : this.state.participationChoice === "no" ? "Nu participa" : "Indecis"}
							activeKey={this.state.participationChoice} className="dropdown_scroll" style={{ border: "1px solid black", margin: "20px 0px" }}>
							<Dropdown.Item eventKey={all} onClick={() => this.changeParticipationChoice(all, false)}> Toti participanti </Dropdown.Item>
							<Dropdown.Item eventKey={participa} onClick={() => this.changeParticipationChoice(participa, false)} > Participa </Dropdown.Item>
							<Dropdown.Item eventKey={nuParticipa} onClick={() => this.changeParticipationChoice(nuParticipa, false)}> Nu Participa </Dropdown.Item>
							<Dropdown.Item eventKey={nuStieDacaParticipa} onClick={() => this.changeParticipationChoice(nuStieDacaParticipa, false)}> Indecis </Dropdown.Item>
						</Dropdown>

						<p>Însoțit</p>
						<Dropdown title={this.state.partnerChoice === all ? "Toti" : this.state.partnerChoice === "yes" ? "Da" : "Nu"}
							activeKey={this.state.partnerChoice} className="dropdown_scroll" style={{ border: "1px solid black", margin: "20px 0px" }}>
							<Dropdown.Item eventKey={all} onClick={() => this.changePartnerChoice(all, false)}> Toti </Dropdown.Item>
							<Dropdown.Item eventKey={participa} onClick={() => this.changePartnerChoice(participa, false)} > Da </Dropdown.Item>
							<Dropdown.Item eventKey={nuParticipa} onClick={() => this.changePartnerChoice(nuParticipa, false)}> Nu </Dropdown.Item>
						</Dropdown>

						<p>Copii</p>
						<Dropdown title={this.state.kidsChoice === all ? "Toti" : this.state.kidsChoice === "yes" ? "Cu" : "Fara"}
							activeKey={this.state.kidsChoice} className="dropdown_scroll" style={{ border: "1px solid black", margin: "20px 0px" }}>
							<Dropdown.Item eventKey={all} onClick={() => this.changeKidsChoice(all, false)}> Toti </Dropdown.Item>
							<Dropdown.Item eventKey={participa} onClick={() => this.changeKidsChoice(participa, false)} > Cu </Dropdown.Item>
							<Dropdown.Item eventKey={nuParticipa} onClick={() => this.changeKidsChoice(nuParticipa, false)}> Fara </Dropdown.Item>
						</Dropdown>

						<p>Data răspuns</p>
						<Dropdown title={this.state.timeChoice === all ? "Toate" : this.state.timeChoice === "24" ? "Ultimele 24 ore" : `Ultimele ${this.state.timeChoice} zile`}
							activeKey={this.state.timeChoice} className="dropdown_scroll" style={{ border: "1px solid black", margin: "20px 0px" }}>
							<Dropdown.Item eventKey={all} onClick={() => this.changeTimeChoice(all, false)}> Toate </Dropdown.Item>
							<Dropdown.Item eventKey={"24"} onClick={() => this.changeTimeChoice("24", false)} > Ultimele 24 ore </Dropdown.Item>
							<Dropdown.Item eventKey={"7"} onClick={() => this.changeTimeChoice("7", false)}> Ultimele 7 zile </Dropdown.Item>
							<Dropdown.Item eventKey={"30"} onClick={() => this.changeTimeChoice("30", false)}> Ultimele 30 zile </Dropdown.Item>
						</Dropdown>

						{this.state.questions.map((question, index) => {
							return <>
								<p>{question.title}</p>

								{question.type === 0
									? <Dropdown title={this.state.questionsFilterOptions[index] === all ? "Toti" : this.state.questionsFilterOptions[index] === participa ? "A raspuns" : "Nu a raspuns"} activeKey={this.state.questionsFilterOptions[index]} className="dropdown_scroll" style={{ border: "1px solid black", margin: "20px 0px" }}>
										<Dropdown.Item eventKey={all} onClick={() => this.changeQuestionFilterChoice(all, index, false)}> Toti </Dropdown.Item>
										<Dropdown.Item eventKey={participa} onClick={() => this.changeQuestionFilterChoice(participa, index, false)} > A Raspuns </Dropdown.Item>
										<Dropdown.Item eventKey={nuParticipa} onClick={() => this.changeQuestionFilterChoice(nuParticipa, index, false)}> Nu a raspuns </Dropdown.Item>
									</Dropdown>
									: <Dropdown title={this.state.questionsFilterOptions[index]} activeKey={this.state.questionsFilterOptions[index]} className="dropdown_scroll" style={{ border: "1px solid black", margin: "20px 0px" }}>
										<Dropdown.Item eventKey={all} onClick={() => this.changeQuestionFilterChoice(all, index, false)}> Toti </Dropdown.Item>
										{question.options.map((option, optionIndex) => {
											return <Dropdown.Item key={optionIndex} eventKey={option} onClick={() => this.changeQuestionFilterChoice(option, index, false)}> {option} </Dropdown.Item>
										})}
									</Dropdown>
								}
							</>
						})}

					</div>
					<div style={{paddingTop:'60px', paddingBottom: '60px'}}> </div>
				</Modal>

				<Modal classNames={{
					overlay: 'modal-overlay',
					modal: 'modal',
					closeButton: 'modal-close-button',
				}} open={this.state.modalInfo} onClose={this.closeModalInfo}>
					<div className="Responses-participation-modal">
						<DataCards cards={[
							{
								data: `Total invitați: ${this.state.totalAnswers} (${this.state.totalFamilies} familii)`,
								color: "#05AFF2"
							},
							{
								data: `Participă: ${this.state.yesAnswers} (${this.state.participateFamilies} familii)`,
								color: "#19B899"
							},
							{
								data: `Nu participă: ${this.state.noAnswers} (${this.state.noParticipateFamilies} familii)`,
								color: "#F26157"
							},
							{
								data: `Indeciși: ${this.state.dontKnowAnswers} (${this.state.dontKnowParticipateFamilies} familii)`,
								color: "#E3B505"
							}
						]} />

						<BackButton text={"Închide"} function={this.closeModalInfo} />
					</div>
				</Modal>

				<HeaderWhite goBack={this.goBack} />

				<div className='Responses-content'>
					<div className='title'>Răspunsuri invitații</div>

					{
						this.state.packageData.limit < 9000 ?
							<div className="">
								Răspunsuri primite: <span style={{color:(this.state.packageData.totalResponses>=this.state.packageData.limit?'red':'green')}}>{this.state.packageData.totalResponses}</span>/{this.state.packageData.limit}
							</div>
							: null
					}

					<div className='options'>
						<div className='answers' onClick={this.openModalInfo}> Sumar răspunsuri </div>

						<div className='filters' onClick={this.openModalFilters}> Filtre răspunsuri</div>
					</div>

					<div style={{display:'flex', flexDirection:'row', width:'90%', marginTop:'10px'}}>
					<div style={{flex:1,}}>
					<div className='add-response-button'>
						<Icon icon="plus-circle" size="lg" style={{ color: "#05AFF2", marginRight: "10px" }} onClick={this.goToAddResponse} />
						<div onClick={this.goToAddResponse}>Adaugă răspuns</div>
					</div>
					</div>

					<div style={{flex:1}}>
						<div className='add-response-button' style={{justifyContent:'flex-end', marginRight:0, width:'100%'}}>
							<Icon icon="plus-circle" size="lg" style={{ color: "#05AFF2", marginRight: "10px" }} onClick={this.goToUploadExcel} />
							<div onClick={this.goToUploadExcel}>Încarcă Excel</div>
						</div>
					</div>
					</div>

					<InputGroup style={{ margin: "10px auto" }}>
						<InputGroup.Addon>
							<Icon icon="search" />
						</InputGroup.Addon>

						<Input onChange={this.onChange} placeholder="Numele invitatului" />
					</InputGroup>

					<DataTable event={this.state.event} dispInv={[...this.state.dispInv]} goToEditResponse={this.goToEditResponse} questions={this.state.questions} />

					<BackAndConfirmButtons
						functionBack={this.goBack}
						textBack={'Înapoi'}
						functionConfirm={()=> this.props.history.push('/organizer/package/' + this.props.match.params.id)}
						textConfirm={"Upgrade pachet"}
						/>
				</div>

				<ShowPackageData
					packageData={this.state.packageData}
					history={this.props.history} eventId={this.props.match.params.id} />

				<Modal
					classNames={{
						overlay: 'modal-overlay',
						modal: 'modal-no-top',
						closeButton: 'modal-close-button',
					}}
					open={this.state.tutorialModal}
					focusTrapped={false}
					onClose={this.updateTutorial}
				>
					<div style={{marginTop: "20px"}}>
						<h3>Informații utile</h3>
						<br/>
						<p>
							Lasa-te pe mana noastra si nu o sa ai parte de surprize neplăcute! În acest meniu poți vizualiza răspunsurile invitatilor referitoare la prezența la evenimentul tău! Vei primi o notificare de fiecare data cand un răspuns este primit. Verifica în secțiunea „Sumar” totalitatea răspunsurilor centralizate: „Da”, „Nu”, „Nu știu”. Pentru cei pe care ii „depășește” tehnologia, poti adauga manual răspunsul acestora.
							<br/><br/>Tot aici ai posibilitatea de a vedea răspunsurile la întrebările „custome„ (personalizate), pe care le-ai trimis odată cu invitația. Folosește un laptop/PC pentru a putea vedea detaliat și aplicând diferite filtre asupra răspunsurilor primite.

						</p>
						<Button
							onClick={this.updateTutorial}
							color="green"
							style={{backgroundColor: "#05AFF2", borderRadius: "20px", width: "100%", marginTop:"20px"}}>Ok</Button>
					</div>
				</Modal>

				<NavbarColor history={this.props.history} />
			</div>
		)
	}
}

export default InviteResponses;
