import React from 'react'
import './EventPage.scss'
import HeaderWhite from '../components/HeaderWhite'
import NavbarColor from '../components/NavbarColor'
import NavBarEvents from '../components/eventsPage/NavBarEvents'

import GeneralTab from './EventPage/GeneralTab'
import AlbumsTab from './EventPage/AlbumsTab'
import FeedTab from './EventPage/FeedTab'
import MemoryBoothTab from './EventPage/MemoryBoothTab'
import Tickets from './TicketsTab/TicketsPage'
import SeatingChart from '../secondaryPages/SeatingChart'
import BalVotes from './BalVotesPage/BalVotes'
import ContactUs from './ContactUs';
import Announcements from './Announcements';
import Partners from './PartnersPage/Partners';
import InfoPage from './InfoPage/InfoPage';
import MenuPage from './MenuPage/MenuPage'
import Participant from './ParticipantPage/Participant';
import Selfie from './SelfiePage/Selfie';
import Feedback from './FeedbackPage/Feedback'
import Votes from './VotingTab/Votes'
import Invite from './InviteTab/Invite'

import { toast } from 'react-toastify'
import { Icon } from 'rsuite'

import axios from 'axios'
import { getUrlFromImage } from '../components/Helper'
import PopularityVotes from './PopularityVotes/PopularityVotes'
import VouchersTab from "./EventPage/VouchersTab";
import LineupTab from "./LineupTab/LineupTab";
import Loader from "../components/Loader";
import LineupDjTab from "./LineupTab/LineupDjTab";
import queryString from 'query-string';
import ParticipantsTab from "./ParticipantsTab/ParticipantsTab";
import DiplomaTab from "./DiplomaTab/DiplomaTab";
import AuctionTab from "./EventPage/AuctionTab/AuctionTab";
import DrawTab from "./DrawTab/DrawTab";

let adsTabs = {
	event: null,
	tickets: null,
	facerecog: null,
	planlocatie: null,
	meniu: null,
	feed: null,
	albums: null,
	announcements: null,
	info: null,
	partners: null
}

class EventPage extends React.Component {



	state = {
		event_id: '',
		event_data: {},
		user_data: {},
		participantData: null,
		activePage: "Eveniment",
		loading: true,
		isTicketPaid: false,

		locations: [],

		unreadContactMessages: 0,

		sideBarIsOn: false,
		tabOptions: [],
		tabOptionsIcons: []
	}

	urlParams = queryString.parse(this.props.location.search)

	componentDidMount = async () => {

		window.scrollTo(0, 0);

		let isAndroid = navigator.userAgent.toLowerCase().indexOf("android") > -1

		if (isAndroid) {
			let viewHeight = window.innerHeight
			let viewport = document.querySelector("meta[name=viewport]");
			viewport.setAttribute("content", "height=" + viewHeight + "px, width=device-width, initial-scale=1.0")
			//document.write('<meta name ="viewport" content="width=device-width, height=' + window.innerHeight+'initial-scale=1.0">');
		}

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		let id = this.props.match.params.id;
		let slug = this.props.match.params.slug ? this.props.match.params.slug : false

		if(localStorage.getItem('jwt') === null) {

			let tab = this.props.match.params.tab ? this.props.match.params.tab : false
			let queryParams = this.props.location.search

			if(slug) {
				this.props.history.push('/public/' + slug + (tab ? '/' + tab : '') + queryParams);
			} else {
				this.props.history.push('/publicEvent/' + id  + (tab ? '/' + tab : '') + queryParams);
			}
			return
		}


		await axios.get(process.env.REACT_APP_DOMAIN + 'users/me', auth)
			.then((response) => {
				this.setState({
					user_data: response.data,
				})
			})
			.catch(err => {
				localStorage.clear()
				toast.error("Informatiile de autentificare au expirat, va rugam sa va autentificati")
				this.props.history.push('/login');
			})

		let payload = {
			id_eveniment: id
		}

		if(slug) {
			payload = {
				slug: slug
			}
		}


		await axios.post(process.env.REACT_APP_DOMAIN + "getEvent", payload, auth)
			.then(async response => {

				if (response.data.tickets_needed !== false) {

					if (this.props.match.params.tab && this.props.match.params.tab === 'ticket') {
						this.setState({
							activePage: 'Bilet'
						})
					}
				}

				if (response.data.activate_auction !== false) {

					if (this.props.match.params.tab && this.props.match.params.tab === 'auction') {
						this.setState({
							activePage: 'Licitatie'
						})
					}
				}

				if (response.data.activate_invite) {
					if (this.props.match.params.tab && this.props.match.params.tab === 'invite') {
						this.setState({
							activePage: 'Invitatie/QR'
						})
					}
				}

				if (response.data.activate_invite) {
						if (this.props.match.params.tab && this.props.match.params.tab === 'info-participant') {
							this.setState({
								activePage: 'Info Participant'
							})
						}
				}

				if (this.props.match.params.tab && this.props.match.params.tab === 'announcements') {
					this.setState({
						activePage: 'Anunturi'
					})
				}

				if (response.data.activate_feedback_questions) {
					if (this.props.match.params.tab && this.props.match.params.tab === 'feedback') {
						this.setState({
							activePage: 'Feedback'
						})
					}
				}


				let locations = []

				for (const [key, value] of Object.entries(response.data.locations_and_dates)) {
					let dateParts = value.date.split("T")
					let dayParts = dateParts[0].split("-")
					let hourParts = dateParts[1].split(":")

					let location = {
						name: key,
						day: dayParts[2] + "-" + dayParts[1] + "-" + dayParts[0],
						hour: hourParts[0] + ":" + hourParts[1],
						location: value.location,
						date: value.date
					}

					if(value.coordinates && value.coordinates.lat && value.coordinates.lng) {
						location.linkWaze = 'https://www.waze.com/ul?ll=' + value.coordinates.lat + '%2C' + value.coordinates.lng + '&navigate=yes&zoom=17'
						location.linkMaps = 'https://maps.google.com?q=' + value.coordinates.lat + "," + value.coordinates.lng
						location.pinx = value.coordinates.lat
						location.piny = value.coordinates.lng
					}

					locations.push(location)
				}

				if(locations) {
					locations = locations.sort((obj1, obj2) => Number(new Date(obj1.date)) - Number(new Date(obj2.date)))
				}
				this.setState({
					locations: locations
				})

				//set available tabs
				var newTabOptions = ["Eveniment"]
				var newTabOptionsIcons = ["star"]

				if (response.data.activate_lineup && (response.data.tip_eveniment === 'festival' || response.data.tip_eveniment === 'concert' || response.data.tip_eveniment === 'conferinta')) {
					newTabOptions.push("Program")
					newTabOptionsIcons.push("info")
				}

				if (response.data.tip_eveniment === 'festival' && response.data.activate_lineupdj) {
					newTabOptions.push("Lineup Dj")
					newTabOptionsIcons.push("info")
				}

				if ((response.data.tip_eveniment === 'conferinta' || response.data.tip_eveniment === 'congress') && response.data.activate_lineupdj) {
					newTabOptions.push("Speakers")
					newTabOptionsIcons.push("info")
				}

				if(response.data.tip_eveniment === 'conferinta' || response.data.tip_eveniment === 'team_building' || response.data.tip_eveniment === 'corporate_party') {
					newTabOptions.push("Info Participant")
					newTabOptionsIcons.push("user-info")
				}

				if (response.data.tickets_needed !== false) {
					newTabOptions.push("Info Participant")
					newTabOptionsIcons.push("user-info")

					newTabOptions.push("Bilet")
					newTabOptionsIcons.push("ticket")
				}

				if (response.data.activate_invite && response.data.tip_eveniment !== 'congress') {
					newTabOptions.push("Invitatie/QR")
					newTabOptionsIcons.push("envelope")
				}

				if(response.data.tip_eveniment === 'conferinta' || response.data.tip_eveniment === 'congress' || response.data.tip_eveniment === 'team_building' || response.data.tip_eveniment === 'corporate_party') {
					if (response.data.participants_tab) {
						newTabOptions.push("Participanți")
						newTabOptionsIcons.push("group")
					}
				}

				if (response.data.FaceRecog.length > 0 && response.data.FaceRecog[0].enable_facerecog) {
					newTabOptions.push("Face Recog")
					newTabOptionsIcons.push("video-camera")
				}

				if (response.data.activate_ball_votes && response.data.published_ball_votes) {
					newTabOptions.push("Miss/Mister")
					newTabOptionsIcons.push("peoples")
				}

				if (response.data.activate_popularity_vote) {
					newTabOptions.push("Regina/Rege")
					newTabOptionsIcons.push("peoples")
				}

				if (response.data.activate_plan_mese !== false) {
					newTabOptions.push("Plan Locatie")
					newTabOptionsIcons.push("braille")
				}

				if (response.data.activate_menu !== false) {
					newTabOptions.push("Meniu")
					newTabOptionsIcons.push("book2")
				}

				if (response.data.activate_feed !== false) {
					newTabOptions.push("Chat")
					newTabOptionsIcons.push("comments")
				}

				if (response.data.memory_booth_files && response.data.memory_booth_files.length > 0) {
					newTabOptions.push("MemoryBooth")
					newTabOptionsIcons.push("comments")
				}

				if (response.data.activate_vouchers && response.data.vouchers && response.data.vouchers.length > 0) {
					newTabOptions.push("Vouchere")
					newTabOptionsIcons.push("comments")
				}

				if (response.data.activate_auction) {
					newTabOptions.push("Licitatie")
					newTabOptionsIcons.push("comments")
				}


				if (response.data.activate_album !== false) {
					newTabOptions.push("Albume")
					newTabOptionsIcons.push("camera")
				}

				newTabOptions.push("Anunturi")
				newTabOptionsIcons.push("bullhorn")

				if (response.data.disable_contact_us_tab !== true) {
					newTabOptions.push("Contact Us")
					newTabOptionsIcons.push("envelope")
				}

				if (response.data.activate_informatii_utile !== false) {
					newTabOptions.push("Informatii Utile")
					newTabOptionsIcons.push("info")
				}

				if (response.data.activate_feedback_questions) {
					newTabOptions.push("Feedback")
					newTabOptionsIcons.push("commenting")

					//Diplome sartiss preconferinta
					if (response.data.id_eveniment === 'JPbCWC2OFX_' || response.data.id_eveniment === 'xnmnJmO8tb') {
						newTabOptions.push("Diploma")
						newTabOptionsIcons.push("file")
					}
				}


				if (response.data.activate_draw && response.data.participant && response.data.participant.qr_checker === true) {
					newTabOptions.push("Extragere")
					newTabOptionsIcons.push("peoples")
				}




				let ticketIsPaid = false


					if (response.data.participant && response.data.participant.email === this.state.user_data.email && response.data.participant.user !== null) {
						ticketIsPaid = response.data.participant.is_paid;
					}


				// if (response.data.activate_vote_tab && response.data.active_vote_set !== null && response.data.active_vote_set >= 0) {
				// 	newTabOptions.push("Voturi")
				// 	newTabOptionsIcons.push("peoples-map")
				// }

				if (response.data.activate_vote_tab && response.data.active_vote_set !== null && response.data.active_vote_set >= 0) {
					let tabNeeded = false

					//check if votes are for ticket buyers
					if (response.data.vote_set[response.data.active_vote_set].onlyForPaidParticipants) {
						//check if participant bought ticket
						if (ticketIsPaid) {
							tabNeeded = true
						}
					} else {
						tabNeeded = true
					}

					if (tabNeeded) {
						newTabOptions.push("Voturi")
						newTabOptionsIcons.push("peoples-map")
					}
				}

				if (response.data.activate_parteneri !== false && response.data.logo_parteneri.length !== 0) {
					newTabOptions.push("Parteneri")
					newTabOptionsIcons.push("handshake-o")
				}

				this.setState({
					event_id: response.data.id_eveniment,
					event_data: response.data,
					unreadContactMessages: response.data.contact_unread_responses,
					tabOptions: newTabOptions,
					tabOptionsIcons: newTabOptionsIcons
				})

				//check ads data
				for (let elem of response.data.ads) {
					//has file and no ad for this location
					if (elem.file !== null && adsTabs[elem.location] === null) {
						let newAdsData = {
							title: elem.title,
							fileType: elem.file.mime.split("/")[0] === "image" ? "image" : "video"
						}

						if (newAdsData.fileType === "image") {
							newAdsData.image = getUrlFromImage(elem.file)
						} else {
							newAdsData.video = elem.file.url
						}

						newAdsData.link = elem.link

						adsTabs[elem.location] = newAdsData
					}
				}

				this.checkTicket(response.data)

				this.setState({
					loading: false
				})
			})
			.catch(err => {
				console.log(err);
				toast.error("Nu exista acest eveniment")
			})

		if (localStorage.getItem('buyingTickets')) {
			localStorage.removeItem('buyingTickets')
			this.goToTickets()
		}
		if (localStorage.getItem('buyingInvites')) {
			localStorage.removeItem('buyingInvites')
			this.goToInvites()
		}
	}

	checkTicket = (event) => {
		if (this.state.isTicketPaid) {
			return
		}


			if (event.participant && event.participant.email === this.state.user_data.email && event.participant.user !== null) {
				// console.log(participants[i])
				this.setState({
					participantData: event.participant,
					isTicketPaid: event.participant.is_paid,
					loading: false,
				})

				return
			}

	}

	reloadEventData = async (goToTickets = false) => {

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		let response = await axios.post(process.env.REACT_APP_DOMAIN + "getEvent", { id_eveniment: this.state.event_id }, auth)

		if (response !== null) {
			this.setState({
				event_data: response.data,
			}, () => {
				if(goToTickets) {
					this.goToTickets()
				}
			})
		}

		this.checkTicket(response.data)
	}

	shoudShowAdModal = (adData, adFieldName) => {
		if (adData !== null) {
			//if add has been seen
			try {
				if (this.state.participantData.seenAds[adFieldName] === adFieldName) {
					return false
				}
			}
			catch {
				//add not seen
			}

			return true
		}

		return false
	}

	updateSeenAds = (adFieldName) => {
		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		let newData = {}
		if (this.state.participantData.seenAds !== null) {
			newData = { ...this.state.participantData.seenAds }
		}
		newData[adFieldName] = adFieldName

		let obj = {
			seenAds: newData
		}

		axios.put(process.env.REACT_APP_DOMAIN + "participants/updateMyParticipant/" + this.state.participantData.id, obj, auth)
			.then(response => {
				let newParticipantData = { ...this.state.participantData }

				newParticipantData.seenAds = response.data.seenAds

				this.setState({
					participantData: newParticipantData
				})
			})
			.catch(err => {
				//toast.error("S-a produs o eroare, va rog reincercati sau reveniti mai tarziu")
			})
	}

	updateFeedbackQuestionsAnswers = (feedbackAnswers) => {
		let newParticipantData = { ...this.state.participantData }

		newParticipantData.questions_answers_feedback = feedbackAnswers

		this.setState({
			participantData: newParticipantData
		})
	}

	changeMenu = (type) => {
		this.setState({
			activePage: type,
			sideBarIsOn: false
		})
	}

	showSideBar = () => {
		var currentState = this.state.sideBarIsOn

		this.setState({
			sideBarIsOn: !currentState
		})
	}

	isRedCircleNeeded = (tabName) => {

		if (this.state.participantData === null) {
			return false
		}

		if (tabName === "Plan Locatie" && this.state.event_data.plan_mese.length > 0 && this.state.participantData.last_plan_mese !== this.state.event_data.plan_mese[0].url) {
			return true
		}

		if (tabName === "Informatii Utile" && this.state.event_data.informatii_utile.length > 0 && this.state.participantData.last_informatii_utile !== this.state.event_data.informatii_utile[0].url) {
			return true
		}

		if (tabName === "Albume" && this.state.event_data.albums && "" + this.state.event_data.albums.length !== this.state.participantData.last_link_poze_public) {
			return true
		}

		if (tabName === "Chat" && this.state.event_data.messages.length !== Number(this.state.participantData.last_feed_count)) {
			return true
		}

		if (tabName === "Feedback" && this.state.participantData.last_feedback_state !== "checked" &&
			this.state.event_data.activate_feedback_questions && this.state.event_data.published_feedback_questions) {
			return true
		}

		if (tabName === "Anunturi" && this.state.event_data.count_announcements > Number(this.state.participantData.last_announcement_count)) {
			return true
		}

		if (tabName === "Voturi" && (this.state.participantData.vote_set_answers === null || this.state.participantData.vote_set_answers.length <= this.state.event_data.active_vote_set)) {
			return true
		}

		if (tabName === "Contact Us" && (this.state.unreadContactMessages && this.state.unreadContactMessages>0)) {
			return true
		}

		return false
	}

	isRedDotNeeded = () => {
		if (this.state.participantData === null) {
			return false
		}

		if (this.state.event_data.activate_plan_mese !== false && this.state.event_data.plan_mese.length > 0 && this.state.participantData.last_plan_mese !== this.state.event_data.plan_mese[0].url) {
			return true
		}

		if (this.state.event_data.activate_informatii_utile !== false && this.state.event_data.informatii_utile.length > 0 && this.state.participantData.last_informatii_utile !== this.state.event_data.informatii_utile[0].url) {
			return true
		}

		if (this.state.event_data.activate_album !== false && this.state.event_data.albums && "" + this.state.event_data.albums.length !== this.state.participantData.last_link_poze_public) {
			return true
		}

		if (this.state.event_data.activate_feed !== false && this.state.event_data.messages.length !== Number(this.state.participantData.last_feed_count)) {
			return true
		}

		if (this.state.event_data.activate_feedback_questions && this.state.participantData.last_feedback_state !== "checked" &&
			this.state.event_data.activate_feedback_questions && this.state.event_data.published_feedback_questions) {
			return true
		}

		if (this.state.event_data.announcement.length !== Number(this.state.participantData.last_announcement_count)) {
			return true
		}

		if (this.state.unreadContactMessages && this.state.unreadContactMessages>0) {
			return true
		}

		return false
	}

	goToContactUs = () => {
		this.setState({
			activePage: "Contact Us"
		})
	}

	goToTickets = () => {
		this.setState({
			activePage: "Bilet"
		})
	}

	goToInvites = () => {
		this.setState({
			activePage: "Invitatie/QR"
		})
	}

	render() {
		if (this.state.loading === true) {
			return <div className="EventPage-container font-family-poppins-300">
				<HeaderWhite shadow={false} />

				<div className="EventPage-contents">
					<Loader/>
				</div>

				<NavbarColor history={this.props.history} />
			</div>
		}

		let toRender = null;

		if (this.state.activePage === "Eveniment")
			toRender = <GeneralTab
				event_id={this.state.event_id} event_data={this.state.event_data} user_data={this.state.user_data} moveToQR={this.moveToQR}
				reloadEventData={this.reloadEventData} locations={this.state.locations} adData={adsTabs["event"]} participantData={this.state.participantData}
				goToTickets={this.goToTickets}
				goToInvite={this.goToInvites}
			/>

		if (this.state.activePage === "Info Participant")
			toRender = <Participant event_id={this.state.event_id} event_data={this.state.event_data} user_data={this.state.user_data}
				participantData={this.state.participantData} goToContactUs={this.goToContactUs} history={this.props.history}
			/>

		if (this.state.activePage === "Bilet")
			toRender = <Tickets
				event_id={this.state.event_id} event_data={this.state.event_data} user_data={this.state.user_data} participantData={this.state.participantData}
				adData={adsTabs["tickets"]} updateSeenAds={this.updateSeenAds} shoudShowAdModal={this.shoudShowAdModal}
			/>

		if (this.state.activePage === "Invitatie" || this.state.activePage === "Invitatie/QR")
			toRender = <Invite
				event_id={this.state.event_id} event_data={this.state.event_data} participantData={this.state.participantData} user_data={this.state.user_data}
			/>

		if (this.state.activePage === "Participanți")
			toRender = <ParticipantsTab event_data={this.state.event_data} />

		if (this.state.activePage === "Face Recog")
			toRender = <Selfie
				event_id={this.state.event_id} event_data={this.state.event_data} email={this.state.user_data.email}
				reloadEventData={this.reloadEventData} isTicketPaid={this.state.isTicketPaid} participantData={this.state.participantData}
				adData={adsTabs["facerecog"]} updateSeenAds={this.updateSeenAds} shoudShowAdModal={this.shoudShowAdModal}
			/>

		if (this.state.activePage === "Miss/Mister")
			toRender = <BalVotes
				event_id={this.state.event_id} event_data={this.state.event_data} participantData={this.state.participantData}
			/>

		if (this.state.activePage === "Regina/Rege")
			toRender = <PopularityVotes
				event_id={this.state.event_id} event_data={this.state.event_data} participantData={this.state.participantData}
			/>

		if (this.state.activePage === "Plan Locatie")
			toRender = <SeatingChart
				event_id={this.state.event_id} event_data={this.state.event_data} participantData={this.state.participantData}
				adData={adsTabs["planlocatie"]} updateSeenAds={this.updateSeenAds} shoudShowAdModal={this.shoudShowAdModal}
			/>

		if (this.state.activePage === "Meniu")
			toRender = <MenuPage
				event_id={this.state.event_id} event_data={this.state.event_data} participantData={this.state.participantData}
				adData={adsTabs["meniu"]} updateSeenAds={this.updateSeenAds} shoudShowAdModal={this.shoudShowAdModal}
			/>

		if (this.state.activePage === "Chat")
			toRender = <FeedTab
				event_id={this.state.event_id} event_data={this.state.event_data} user_data={this.state.user_data}
				reloadEventData={this.reloadEventData} isTicketPaid={this.state.isTicketPaid} participantData={this.state.participantData}
				adData={adsTabs["feed"]} updateSeenAds={this.updateSeenAds} shoudShowAdModal={this.shoudShowAdModal}
			/>

		if (this.state.activePage === "MemoryBooth")
			toRender = <MemoryBoothTab
				event_data={this.state.event_data}
			/>

		if (this.state.activePage === "Vouchere")
			toRender = <VouchersTab
				event_data={this.state.event_data}
				isTicketPaid={this.state.isTicketPaid}
			/>

		if (this.state.activePage === "Licitatie")
			toRender = <>
				<div style={{display:"none"}}>
					<GeneralTab
						event_id={this.state.event_id} event_data={this.state.event_data} user_data={this.state.user_data} moveToQR={this.moveToQR}
						reloadEventData={this.reloadEventData} locations={this.state.locations} adData={adsTabs["event"]} participantData={this.state.participantData}
						goToTickets={this.goToTickets}
						goToInvite={this.goToInvites}
					/>
				</div>

				<AuctionTab
				event_data={this.state.event_data}
				participantData={this.state.participantData}
				urlParams={this.urlParams}
				history={this.props.history}
			/></>

		if (this.state.activePage === "Albume")
			toRender = <AlbumsTab
				event_id={this.state.event_id} event_data={this.state.event_data} user_data={this.state.user_data}
				reloadEventData={this.reloadEventData} isTicketPaid={this.state.isTicketPaid} participantData={this.state.participantData}
				adData={adsTabs["albums"]} updateSeenAds={this.updateSeenAds} shoudShowAdModal={this.shoudShowAdModal}
			/>

		if (this.state.activePage === "Anunturi")
			toRender = <Announcements
				event_id={this.state.event_id} event_data={this.state.event_data} participantData={this.state.participantData}
				adData={adsTabs["announcements"]} updateSeenAds={this.updateSeenAds} shoudShowAdModal={this.shoudShowAdModal}
			/>

		if (this.state.activePage === "Contact Us")
			toRender = <ContactUs
				event_id={this.state.event_id} unreadMessages={this.state.unreadContactMessages} clearUnreadMessages={() => this.setState({unreadContactMessages: 0})} event_data={this.state.event_data} user_data={this.state.user_data}
			/>

		if (this.state.activePage === "Informatii Utile")
			toRender = <InfoPage
				event_id={this.state.event_id} event_data={this.state.event_data} participantData={this.state.participantData}
				adData={adsTabs["info"]} updateSeenAds={this.updateSeenAds} shoudShowAdModal={this.shoudShowAdModal}
			/>

		if (this.state.activePage === "Feedback")
			toRender = <Feedback event_id={this.state.event_id} event_data={this.state.event_data} user_data={this.state.user_data}
				participantData={this.state.participantData} updateFeedbackAnswers={this.updateFeedbackQuestionsAnswers}
			/>

		if (this.state.activePage === "Diploma")
			toRender = <DiplomaTab event_id={this.state.event_id} event_data={this.state.event_data} user_data={this.state.user_data}
								 participantData={this.state.participantData} updateFeedbackAnswers={this.updateFeedbackQuestionsAnswers}
								   goToFeedbackTab={() => this.setState({activePage: 'Feedback'})}
			/>

		if (this.state.activePage === "Extragere")
			toRender = <DrawTab event_data={this.state.event_data} participantData={this.state.participantData} />

		if (this.state.activePage === "Voturi")
			toRender = <Votes event_id={this.state.event_id} event_data={this.state.event_data} user_data={this.state.user_data}
				participantData={this.state.participantData}
			/>

		if (this.state.activePage === "Parteneri")
			toRender = <Partners
				event_id={this.state.event_id} event_data={this.state.event_data}
				adData={adsTabs["partners"]} updateSeenAds={this.updateSeenAds} shoudShowAdModal={this.shoudShowAdModal}
			/>

		if (this.state.activePage === "Program")
			toRender = <LineupTab
				event_id={this.state.event_id} event_data={this.state.event_data} history={this.props.history}
			/>

		if (this.state.activePage === "Lineup Dj")
			toRender = <LineupDjTab
				event_id={this.state.event_id} event_data={this.state.event_data} history={this.props.history}
			/>

		if (this.state.activePage === "Speakers")
			toRender = <LineupDjTab
				event_id={this.state.event_id} event_data={this.state.event_data} history={this.props.history}
			/>


		return (
			<div className="EventPage-container font-family-poppins-300">

				<HeaderWhite shadow={false} />

				<div className="EventPage-contents">
					<NavBarEvents
						activePage={this.state.activePage}
						changeMenu={this.changeMenu}
						showSideBar={this.showSideBar}
						isRedCircleNeeded={this.isRedCircleNeeded}
						tabOptions={this.state.tabOptions}
						isDotNeeded={this.isRedDotNeeded()}
					/>

					<div className="PageContent">
						<div>
							{toRender}
						</div>
					</div>
				</div>

				{this.state.sideBarIsOn && <div>
					<div className="side-bar">
						{this.state.tabOptions.map((option, index) => {
							return <div onClick={() => this.changeMenu(option)} className="side-bar-line" key={option}>
								<Icon icon={this.state.tabOptionsIcons[index]} style={{ color: this.isRedCircleNeeded(option) ? "red" : this.state.activePage === option ? "#1DE9B6" : "white" }} />

								<p className={this.state.activePage === option ? "side-bar-item-active" : "side-bar-item"}>{option}</p>

								<div className={this.isRedCircleNeeded(option) ? "side-bar-dot" : ""}></div>
							</div>
						})}
					</div>

					<div className="side-bar-blur" onClick={this.showSideBar}></div>
				</div>}

				<NavbarColor history={this.props.history} />
			</div>
		)
	}
}

export default EventPage;
